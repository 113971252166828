import {Box, Divider, Grid, Typography} from "@mui/material";
import ConsumptionOfBeer from "../assets/images/consumptionOfBeer.png";
import ConsumptionOfBeer2 from '../assets/images/consumptionOfBeer2.png';
import Worth from '../assets/images/worth.png';

const Market = (props: {marginRight: number, marketRef: any}) => {
    return (
        <Grid container ref={props.marketRef} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Rynek</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Typography variant={"body1"} pt={6} sx={{lineHeight: '30px'}}>Piwo jest jednym z najbardziej popularnych napojów na świecie i w Polsce.</Typography>
            <Typography variant={"body1"} pb={3} sx={{lineHeight: '30px'}}>Pod względem wielkości sprzedaży rynek w Polsce jest jednym z największych w Europie i na świecie.</Typography>
            <Box component={"img"} src={ConsumptionOfBeer} sx={{maxWidth: '930px', width: '100%'}} />
            <Typography sx={{fontWeight: 300, fontSize: '16px!important'}} mt={3}>Źródło: The Brewers of Europe, ZPPP Browary Polskie.</Typography>

            <Typography pt={5}>Wartość rynku w Polsce to ponad 22 miliardy złotych w cenach sprzedaży dla konsumenta.</Typography>
            <Typography sx={{fontWeight: 300, fontSize: '16px!important'}}>(Źródło: obliczenia własne spółki na podstawie wielkości rynku i średniej ceny piwa). </Typography>

            <Typography mt={7}>Średnia konsumpcja piwa w Polsce wynosi niemal 100 litrów na osobę i znacznie przekracza średnią światową i europejską. Przynajmniej raz w miesiącu piwo pije 70% dorosłych Polaków.</Typography>
            <Box component={"img"} mt={6} src={ConsumptionOfBeer2} sx={{maxWidth: '930px', width: '100%'}} />
            <Typography mt={3} sx={{fontWeight: 300, fontSize: '16px!important'}}>Źródło: The Brewers of Europe, ZPPP Browary Polskie.</Typography>

            <Typography mt={8}>Konsumpcja piwa rzemieślniczego rośnie na całym świecie, w tym w Europie i w Polsce. W Polsce tempo wzrostu wynosi 40-50% rocznie.</Typography>
            <Typography mt={3}>W Polsce grupa docelowa piwa rzemieślniczego to ponad 4 mln. osób, co stanowi około 30% wszystkich konsumentów piwa.</Typography>

            <Typography sx={{fontWeight: 300, fontSize: '16px!important'}} mt={8}>Źródło: obliczenia własne Browaru Za Miastem na podstawie The Brewers Association, Browary Polskie, GUS.</Typography>
            <Box component={"img"} mt={6} src={Worth} sx={{maxWidth: '930px', width: '100%'}} />
            <Typography mt={3} sx={{fontWeight: 300, fontSize: '16px!important'}}>Źródło: Craft Beer Market in Europe by Product.</Typography>
        </Grid>
    )
}

export default Market;
