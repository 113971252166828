import {Box, Button, Divider, FormControlLabel, FormGroup, Grid, Typography, useTheme} from "@mui/material";
import {useContext, useState} from "react";
import BuySharesContext from "../../store/buy-shares-context";
import CustomCheckbox from "./CustomCheckbox";
import SnackbarContext from "../../store/snackbar-context";
import {Md5} from "ts-md5";
import {getSignature} from "../../api/payment-api";

const FifthStep = () => {
    const {palette} = useTheme();
    const ctx = useContext(BuySharesContext);
    const {setMsg} = useContext(SnackbarContext);

    const goToPayment = () => {
        const totalPrice = ctx.unitPrice * (ctx.sharesNumber ? ctx.sharesNumber : 0);
        const description = "Zapis na akcje Nasz Browar"
        const crc = ctx.pesel ? ctx.pesel : ctx.nip
        getSignature({totalPrice, crc}, (error) => {
            setMsg({msg: 'Błąd transakcji.'})
        }).then(code => {
            if(code) {
                const tpayId = process.env.REACT_APP_TPAY_ID;
                const url = `https://secure.tpay.com?id=${tpayId}&amount=${totalPrice}&description=${description}&crc=${crc}&md5sum=${code}`
                window.open(url, '_blank');
            } else {
                setMsg({msg: 'Błąd transakcji.'})
            }
        }).catch(error => {
            console.log(error);
            setMsg({msg: 'Błąd transakcji.'})
        })
    }

    return <Grid item container id={"fifth-step"} sx={{
        backgroundColor: palette.primary.light,
        width: "100%",
        mt: "106px",
    }}>
        <Grid item container flexDirection={"column"} sx={{
            maxWidth: '1450px',
            margin: '0 auto',
            padding: '0 50px',
            position: 'relative',
        }}>
            <Typography variant={"h3"} mt={"87px"} mb={"35px"}>Opłać akcje i czekaj na e-mail z potwierdzeniem zakupu</Typography>
            <Divider sx={{backgroundColor: palette.primary.dark, maxWidth: "1020px"}}/>
            <Grid item container>
            </Grid>
            <Button sx={{
                maxWidth: '339px',
                m: "40px 0 64px 0",
                textAlign: 'center',
                height: "70px",
                fontSize: '30px',
                fontWeight: 600,
                backgroundColor: palette.secondary.light,
                borderRadius: 0
            }} variant='contained' onClick={() => goToPayment()}>Zapłać online</Button>
            <Grid item sx={{width: "70%"}} mb={"81px"}>
                <Typography mb={"81px"} display={"inline"} sx={{fontSize: "20px !important", fontWeight: 400}}>Jeśli nie dokonujesz płatności online przelej wskazaną kwotę na rachunek bankowy Emitenta
                    numer:&nbsp;</Typography>
                <Typography mb={"81px"} display={"inline"} sx={{fontSize: "20px !important", fontWeight: 600}}>39 1050 1520 1000 0090 8100 2967</Typography>
            </Grid>
      </Grid>
    </Grid>
}

export default FifthStep;