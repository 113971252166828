import {Divider, Box, Grid, Link, Typography, styled, IconButton} from "@mui/material";
import InstagramLogo from "../ui/icons/InstagramLogo";
import FacebookLogo from "../ui/icons/FacebookLogo";
import {useContext} from "react";
import ContentContext from "../store/content-context";

const TypographyStyled = styled(Typography)(({theme}) => ({
    fontSize: '19px!important',
    fontWeight: 600,
    padding: '40px 40px',
    textAlign: 'center',
}))

const Documents = (props: {marginRight: number, documentsRef: any}) => {
    const {setting} = useContext(ContentContext);

    return (
        <Grid container ref={props.documentsRef} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Dokumenty</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Box sx={{display: 'flex', margin: '0 auto', flexDirection: {xs: 'column', lg: 'row'}, gap: {xs: '30px', lg: '10px'}}} pt={4}>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>Dokument informacyjny*</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/BZM_dokument_informacyjny_2022.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>Sprawozdanie finansowe 2020</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/Sprawozdanie Finansowe 2020.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>Sprawozdanie finansowe 2021</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/Sprawozdanie Finansowe 2021.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex', margin: '0 auto', flexDirection: {xs: 'column', lg: 'row'}, gap: {xs: '30px', lg: '10px'}}} pt={4}>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>2021 sprawozdanie finansowe podpisane</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/BROWAR_ZA_MIASTEM_SPZOO_SKA_2021.xml"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>2020 sprawozdanie finansowe podpisane</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/Browar_Za_Miastem_SKA_2020-sprawozdanie_zarządu_podpis.xml"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>2021 informacje dodatkowe</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/Browar_SKA_Informacja_dodatkowa_2021.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex', margin: '0 auto', flexDirection: {xs: 'column', lg: 'row'}, gap: {xs: '30px', lg: '10px'}}} pt={4}>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>2020 informacje dodatkowe</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/Browar_SKA_Informacja_dodatkowa_2020.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>Statut spółki – aktualna treść</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/statut_spółki_monitor sądowy_ogłoszenie.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'center', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>Wzór formularza zapisu na akcje serii L</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/formularz_zapisu_na_akcje_wzór.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex', margin: '0 auto', flexDirection: {xs: 'column', lg: 'row'}, gap: {xs: '30px', lg: '10px'}}} pt={4}>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>Uchwała o emisji akcji serii L</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/uchwała-o-emisji-akcji-serii-L.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>Uchwała o emisji akcji serii L, punkt 7  – sprostowanie</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/uchwała_o_emisji_akcji_serii_L_punkt 7_sprostowanie.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '300px', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #707070'}}>
                    <TypographyStyled>Ogłoszenie o emisji – Monitor Sądowy</TypographyStyled>
                    <Box sx={{textAlign: 'center', padding: '15px 0', backgroundColor: '#351202', fontSize: '18px', fontWeight: 600, color: '#fff', width: '100%', textDecoration: 'none'}}>
                        <Link href={"/ogłoszenie_o_emisji_Monitor Sądowy.pdf"} download target="_blank" sx={{color: '#fff',textDecoration: 'none'}}>
                            Pobierz
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Typography sx={{padding:"10px", fontSize: "14px !important"}}>*Niniejszy dokument informacyjny został zaktualizowany w stosunku do pierwotnej wersji, zmieniono treść: pkt 2.6, pkt 3.3, pkt 4, pkt 5.18. Dokument w aktualnym brzmieniu został umieszczony na stronie internetowej dedykowanej emisji akcji serii L  w dniu 09 września 2022 r.</Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}} mt={6}>
                <Typography sx={{color: '#000', textDecoration: 'none', cursor: 'pointer'}} onClick={() => window.open('https://www.za-miastem.pl', '_blank')}>
                        www.za-miastem.pl
                </Typography>
                <Box sx={{display: 'flex'}} ml={2}>
                    <IconButton sx={{'&:hover': {backgroundColor: 'transparent'}, width: '46px'}} target="_blank"
                                href={setting.instagram}><InstagramLogo/></IconButton>
                    <IconButton sx={{'&:hover': {backgroundColor: 'transparent'}, width: '46px'}} target="_blank"
                                href={setting.facebook}><FacebookLogo/></IconButton>
                </Box>
            </Box>
        </Grid>
    )
}

export default Documents
