import {Box, Button, Divider, Grid, styled, Typography} from "@mui/material";
import panda from "../assets/images/panda.svg";
import {useContext} from "react";
import ContentContext from "../store/content-context";
import {useNavigate} from "react-router-dom";

const BoxGrid = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xl')]: {
        alignItems: 'center',
    },
}))

const TypographyColor = styled(Typography)(({theme}) => ({
    color: '#8e6f5f'
}))

const FormStocksMin = () => {
    const {setting} = useContext(ContentContext);
    const navigate = useNavigate();

    const getButton = (enabled: Boolean = false) => {
        const title = enabled ? 'Kup akcje' : 'Emisja zakończona';
        const action = enabled ? () => navigate('/kup-akcje') : () => {};
        const pointerEvents = enabled ? 'auto' : 'none';
        return <Button sx={{
            width: '80%',
            textAlign: 'center',
            padding: '10px 0',
            pointerEvents: pointerEvents,
            fontSize: '20px',
            fontWeight: 600,
            backgroundColor: '#9ba037',
            borderRadius: 0,
            margin: {xs: '10px auto 30px auto', lg: '15px auto 60px auto'}
        }} variant='contained' onClick={action}>{title}</Button>
    }

    return (
        <Grid container flexDirection={'column'} sx={{position: 'relative', width: {xs: '300px', sm: '400px', md: '600px'}}} mt={12}>
            <Box component="img" src={panda} sx={{position: 'absolute', width: '40%', top: '-20%', left: '0%'}} />
            <Grid container sx={{textAlign: 'center', boxShadow: '0px 8px 8px 0px rgba(183, 182, 182, 1)', backgroundColor: '#fff'}}>
                <Box ml={{xs: 0, xl: 6}} sx={{width: {xs: '100%', xl: '80%'}}}>
                    <Typography variant="h2" color={'#8e6f5f'} mt={{xs: 8, xl: 12}} mb={1} sx={{textAlign: {xs: 'center', xl: 'left'}}}>{setting.amount} zł</Typography>
                    <Divider sx={{width: {xs: '90%', xl: '95%'}, borderColor: '#8e6f5f', margin: {xs: '0 auto', xl: 'auto'}}}/>
                    <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr 1fr', gap: '20px', width: {xs: '100%', xl: '90%'}, justifyContent: {xs: 'center', xl: 'space-around'}}} mt={3}>
                        <BoxGrid sx={{gridColumn: '1/1', gridRow: '1/1'}}>
                            <TypographyColor variant="h2">{setting.investors}</TypographyColor>
                            <TypographyColor variant="h6">inwestorów</TypographyColor>
                        </BoxGrid>
                        <BoxGrid sx={{gridColumn: '2/3', gridRow: '1/1'}}>
                            <TypographyColor variant="h2">{setting.days} dni</TypographyColor>
                            <TypographyColor variant="h6">do końca</TypographyColor>
                        </BoxGrid>
                        <BoxGrid sx={{gridColumn: '1/1', gridRow: '2/3'}}>
                            <TypographyColor variant="h5">{setting.sharePrice} zł</TypographyColor>
                            <TypographyColor variant="h6">cena akcji</TypographyColor>
                        </BoxGrid>
                        <BoxGrid sx={{gridColumn: '2/3', gridRow: '2/3'}}>
                            <TypographyColor variant="h5">{setting.amountOfShares}</TypographyColor>
                            <TypographyColor variant="h6">liczba akcji</TypographyColor>
                        </BoxGrid>
                    </Box>
                </Box>
                {getButton(setting.days > 0)}
            </Grid>
        </Grid>
    )
}

export default FormStocksMin;
