import {Grid, Box, Typography, Divider, Button, styled} from "@mui/material";
import {useContext, useState} from "react";
import panda from '../assets/images/panda.svg';
import ContentContext from "../store/content-context";
import {useNavigate} from "react-router-dom";

const BoxGrid = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xl')]: {
        alignItems: 'center',
    },
}))

const TypographyColor = styled(Typography)(({theme}) => ({
    color: '#8e6f5f'
}))

const FormStocks = () => {
    const {setting} = useContext(ContentContext);
    const navigate = useNavigate();
    const [float, setFloat] = useState(false);

    window.onscroll = () => {
        const pandaContainer = document.getElementById("banner-container");
        const panda = document.getElementById("floating-panda");
        if (pandaContainer && panda) {
            if (window.innerHeight / 2 > (pandaContainer.getBoundingClientRect().top + (panda.getBoundingClientRect().height / 2) + 50)) {
                setFloat(true);
            } else {
                setFloat(false);
            }
        }
    }

    const getButton = (enabled: Boolean = false) => {
        const title = enabled ? 'Kup akcje' : 'Emisja zakończona';
        const action = enabled ? () => navigate('/kup-akcje') : () => {};
        const pointerEvents = enabled ? 'auto' : 'none';
        return <Button sx={{
            width: '80%',
            textAlign: 'center',
            padding: '10px 0',
            pointerEvents: pointerEvents,
            fontSize: '20px',
            fontWeight: 600,
            backgroundColor: '#9ba037',
            borderRadius: 0,
            margin: {xs: '10px auto 30px auto', lg: '15px auto 60px auto'}
        }} variant='contained' onClick={action}>{title}</Button>
    }

    return (
        <Grid id={"floating-panda"} container flexDirection={'column'} sx={{
            position: float ? 'fixed' : 'absolute',
            top: float ? "50%" : "50px",
            transform: float ? "translateY(-50%)" : "none",
            right: {xs: '85%', lg: '75%', xl: '65%'},
            zIndex: 100,
            maxWidth: {xs: '250px', xl: '360px'}
        }}>
            <Box component="img" src={panda} sx={{
                position: 'absolute',
                width: {xs: '40%', lg: '50%', xl: '60%'},
                top: {xs: '-9%', xl: '-20%'},
                left: {xs: '3%', xl: '-4%'}
            }}/>
            <Grid container sx={{
                textAlign: 'center',
                boxShadow: '0px 8px 8px 0px rgba(183, 182, 182, 1)',
                backgroundColor: '#fff'
            }}>
                <Box ml={{xs: 0, xl: 6}} sx={{width: {xs: '100%', xl: '80%'}}}>
                    <Typography variant="h2" color={'#8e6f5f'} mt={{xs: 8, xl: 12}} mb={1}
                                sx={{textAlign: {xs: 'center', xl: 'left'}}}>{setting.amount} zł</Typography>
                    <Divider sx={{
                        width: {xs: '90%', xl: '95%'},
                        borderColor: '#8e6f5f',
                        margin: {xs: '0 auto', xl: 'auto'}
                    }}/>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: {xs: '1fr', xl: '1fr 1fr'},
                        gridTemplateRows: {xs: 'repeat(4, 1fr)', xl: '1fr 1fr'},
                        gap: {xs: '10px', xl: '20px'},
                        width: {xs: '100%', xl: '90%'},
                        justifyContent: {xs: 'center', xl: 'space-around'}
                    }} mt={3}>
                        <BoxGrid sx={{gridColumn: '1/1', gridRow: '1/1'}}>
                            <TypographyColor variant="h2">{setting.investors}</TypographyColor>
                            <TypographyColor variant="h6">inwestorów</TypographyColor>
                        </BoxGrid>
                        <BoxGrid sx={{gridColumn: {xs: '1/1', xl: '2/3'}, gridRow: {xs: '2/3', xl: '1/1'}}}>
                            <TypographyColor variant="h2"
                                             sx={{whiteSpace: "nowrap"}}>{setting.days} dni</TypographyColor>
                            <TypographyColor variant="h6">do końca</TypographyColor>
                        </BoxGrid>
                        <BoxGrid sx={{gridColumn: '1/1', gridRow: {xs: '3/4', xl: '2/3'}}}>
                            <TypographyColor variant="h5">{setting.sharePrice} zł</TypographyColor>
                            <TypographyColor variant="h6">cena akcji</TypographyColor>
                        </BoxGrid>
                        <BoxGrid sx={{gridColumn: {xs: '1/1', xl: '2/3'}, gridRow: {xs: '4/5', xl: '2/3'}}}>
                            <TypographyColor variant="h5">{setting.amountOfShares}</TypographyColor>
                            <TypographyColor variant="h6">liczba akcji</TypographyColor>
                        </BoxGrid>
                    </Box>
                </Box>
                {getButton(setting.days > 0)}
            </Grid>
        </Grid>
    )
}

export default FormStocks;
