import {useMediaQuery, useTheme} from "@mui/material";
import FullHeader from "./FullHeader";
import MinHeader from "./MinHeader";

const Header = (props: {marginRight: number, refers: any}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    return <>
        {matches ? <FullHeader refers={props.refers} marginRight={props.marginRight} /> : <MinHeader marginRight={props.marginRight} refers={props.refers} />}
    </>
}

export default Header;
