import {Divider, Grid, Box, Typography} from "@mui/material";
import TomaszBrycki from '../assets/images/Tomasz_Brycki.png';
import MarekWziątek from '../assets/images/Marek_Wziątek.png';

const Team = (props: {marginRight: number, teamRef: any}) => {
    return (
        <Grid container ref={props.teamRef} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Zespół</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Typography pt={6}>Przez pierwsze lata zespół był utrzymywany na minimalnym poziomie.
            Początkowo składał się wyłącznie z dwóch założycieli. Zespół stopniowo powiększał się wraz z
                rozwojem przychodów.</Typography><Typography pb={6}><Box component={"span"} sx={{fontWeight: 600}}>W sierpniu 2022 Zespół liczy 9 osób, w tym 2 założycieli, 4 piwowarów, 2 osoby
                    zajmujące się logistyką i obsługą sprzedaży oraz 1 handlowca. Założyciele zjamują się Spółką w pełnym zakresie.</Box></Typography>
            <Typography pt={2} pb={8} sx={{fontWeight: 600}}>Zarząd Spółki:</Typography>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center'}}>
                    <Box component={"img"} src={TomaszBrycki} sx={{width: '260px', height: '260px'}} pb={{xs: 4, md: 0}} />
                    <Box ml={{xs: 0, md: 4, lg: 10}}>
                        <Typography pb={2} sx={{fontWeight: 600}}>Tomasz Brycki: założyciel, prezes spółki.</Typography>
                        <Typography>Zajmuje się strategią, produkcją i finansami. Prowadził budowę browaru.
                        Ponad 25 lat w branży piwnej. W latach 1996-2016 pracował w Kompanii Piwowarskiej. Współtwórca takich marek
                        jak Żubr i Dębowe. Zarządzał marką Tyskie. Zajmował się strategią portfela marek oraz strategią zarządzania
                        kategorią piwa. Członek Stowarzyszenia Polskich Browarów Rzemieślniczych.</Typography>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center'}} pt={8}>
                    <Box component={"img"} src={MarekWziątek} sx={{width: '260px', height: '260px'}} pb={{xs: 4, md: 0}} />
                    <Box ml={{xs: 0, md: 4, lg: 10}}>
                        <Typography pb={2} variant={"h4"}>Marek Wziątek: założyciel, wiceprezes spółki.</Typography>
                        <Typography>Zajmuje się sprzedażą i marketingiem. Zarządza sprzedażą, odpowiada za relacje z głównymi klientami browaru.
                            Ponad 17 lat w branży piwnej. Zarządzał markami i projektami w dużych międzynarodowych firmach. Pracował w Kompanii Piwowarskiej, w której m.in. zarządzał marką Żubr, największą marką piwną na rynku.</Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}

export default Team;
