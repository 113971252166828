import {
    Button,
    FormControlLabel,
    FormGroup,
    Grid, useMediaQuery,
    useTheme
} from "@mui/material";
import {useContext, useState} from "react";
import BuySharesContext from "../../store/buy-shares-context";
import CustomCheckbox from "./CustomCheckbox";
import SnackbarContext from "../../store/snackbar-context";

const ThirdStep = () => {
    const {palette, breakpoints} = useTheme();
    const {setStep, setIsCompany, isCompany} = useContext(BuySharesContext);
    const {setMsg} = useContext(SnackbarContext);
    const [error, setError] = useState(false);

    const handleGoNext = () => {
        if(isCompany === undefined) {
            setError(true);
            setMsg({msg: "Wybierz jedną z opcji", severity: "warning"})
        } else {
            setError(false);
            setStep(3);
        }
    }

    const handleOnChange = (value: boolean) => {
        setIsCompany(value);
        setError(false);
    }

    return <Grid item container id="third-step" sx={{
        backgroundColor: palette.primary.light,
        width: "100%",
        mt: "106px",
    }}>
        <Grid item container flexDirection={"column"} sx={{
            maxWidth: '1450px',
            margin: '0 auto',
            padding: '0 50px',
            position: 'relative',
        }}>
            <Grid item container>
                <FormGroup sx={{flexDirection: "row", mt: "91px", mb: "78px"}}>
                    <FormControlLabel control={<CustomCheckbox isError={error} checkboxProps={{
                        checked: isCompany !== undefined && !isCompany,
                        onChange: () => handleOnChange(false)
                    }}/>}
                                      label={"Osoba fizyczna"} sx={{
                        pl: {xs: "10px", md: 0},
                        "& span": {
                            color: "black",
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                            fontSize: "26px"
                        }}}/>
                    <FormControlLabel control={<CustomCheckbox isError={error} checkboxProps={{
                        checked: isCompany !== undefined && isCompany,
                        onChange: () => handleOnChange(true)
                    }}/>}
                                      label={"Firma"} sx={{
                        ml: {xs: 0, md: "74px"},
                        "& span": {
                            color: "black",
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                            fontSize: "26px"
                        },
                    }}/>
                </FormGroup>
            </Grid>
            <Button sx={{
                maxWidth: '339px',
                m: "0 0 64px 0",
                textAlign: 'center',
                height: "70px",
                fontSize: '30px',
                fontWeight: 600,
                backgroundColor: palette.secondary.light,
                borderRadius: 0
            }} variant='contained' onClick={() => handleGoNext()}>Dalej</Button>
        </Grid>
    </Grid>
}

export default ThirdStep;