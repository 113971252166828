import {Divider, Box, Grid, Typography, styled} from "@mui/material";
import lines from '../assets/images/lines.svg';

const TypographyStyled = styled(Typography)(({theme}) => ({
    fontSize: '18px',
    fontWeight: 600,
    marginLeft: '20px'
}))

const BoxLiStyled = styled(Box)(({theme}) => ({
    '&::marker': {fontSize: '10px'}
}))

const History = (props: {marginRight: number, historyRef: any}) => {
    return (
        <Grid container ref={props.historyRef} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Historia</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Grid container position={'relative'} pt={10}>
                <Box component={"img"} src={lines} sx={{position: 'absolute', top: '8.5%', display: {xs: 'none', md: 'block'}}} />
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} ml={{xs: 0, md: 10}}>
                    <Box>
                        <TypographyStyled mt={-1}>2016</TypographyStyled>
                        <Box component={"ul"} m={0}>
                            <BoxLiStyled component={"li"}>Założenie firmy Browar Za Miastem.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Powstają pierwsze plany browaru i pierwsze receptury piwa.</BoxLiStyled>
                        </Box>
                    </Box>
                    <Box>
                        <TypographyStyled mt={4}>2017</TypographyStyled>
                        <Box component={"ul"} m={0}>
                            <BoxLiStyled component={"li"}>Początek produkcji piwa pod własną marką.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Pierwsze 4 piwa w sprzedaży. Pierwsze nagrody dla piw (konkurs KPR Kraft Roku).</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Kupno działki budowlanej pod budowę browaru.</BoxLiStyled>
                        </Box>
                    </Box>
                    <Box>
                        <TypographyStyled mt={4}>2018</TypographyStyled>
                        <Box component={"ul"} m={0}>
                            <BoxLiStyled component={"li"}>Liczba piw rośnie i przekracza 10 gatunków.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Dostępność piw rozszerza się na całą Polskę. Początek współpracy z sieciami handlowymi.</BoxLiStyled>
                        </Box>
                    </Box>
                    <Box>
                        <TypographyStyled mt={4}>2019</TypographyStyled>
                        <Box component={"ul"} m={0}>
                            <BoxLiStyled component={"li"}>Pierwsze piwa długo leżakowane (imperialny stout).</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Zdobycie dotacji unijnej na zakup urządzeń produkcyjnych.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Rozpoczęcie współpracy z kolejnymi sieciami handlowymi.</BoxLiStyled>
                        </Box>
                    </Box>
                    <Box>
                        <TypographyStyled mt={4}>2020</TypographyStyled>
                        <Box component={"ul"} m={0}>
                            <BoxLiStyled component={"li"}>Produkcja piwa w trzech browarach rzemieślniczych.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Liczba piw zbliża się do 20.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Początek budowy własnego browaru.</BoxLiStyled>
                        </Box>
                    </Box>
                    <Box>
                        <TypographyStyled mt={4}>2021</TypographyStyled>
                        <Box component={"ul"} m={0}>
                            <BoxLiStyled component={"li"}>Zakończenie prac budowlanych.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Dostawa i instalacja pierwszych urządzeń produkcyjnych.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Liczba piw w ofercie przekracza 20. Kilkanaście nowości.</BoxLiStyled>
                        </Box>
                    </Box>
                    <Box>
                        <TypographyStyled mt={4.5}>2022</TypographyStyled>
                        <Box component={"ul"} m={0}>
                            <BoxLiStyled component={"li"}>Uruchomienie produkcji we własnym nowym browarze.</BoxLiStyled>
                            <BoxLiStyled component={"li"}>Liczba piw w ofercie zbliża się do 30.</BoxLiStyled>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default History;
