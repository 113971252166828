import {Divider, Grid, Typography, Box, styled} from "@mui/material";
import barrel from '../assets/images/barrel.png';
import beer from '../assets/images/beer.png';
import growth from '../assets/images/growth.png';
import hands from '../assets/images/hands.png';

const TypographySubscribe = styled(Typography)(({theme}) => ({
    lineHeight: '30px'
}))

const TypographyImage = styled(Typography)(({theme}) => ({
    textAlign: 'center',
    fontSize: '20px!important',
    fontWeight: 400
}))

const ContainerImage = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
}))

const BoxImage = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    height: '180px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '20px'
    },
}))

const DividerStyled = styled(Divider)(({theme}) => ({
    width: '90%',
    paddingTop: '40px',
    borderColor: '#707070',
    [theme.breakpoints.down('lg')]: {
        paddingTop: '20px'
    },
}))

const Summary = (props: {marginRight: number, summaryRef: any}) => {

    return (
        <Grid container ref={props.summaryRef} flexDirection={"column"} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Podsumowanie</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <TypographySubscribe variant={"body1"} pt={3}><Box component={"span"} sx={{fontWeight: 600}}>Celem emisji jest pozyskanie środków na rozwój browaru: zwiększenie możliwości
                produkcyjnych i ekspansję rynkową.</Box>
            </TypographySubscribe>
            <TypographySubscribe variant={"body1"}>Spółka działa na ogromnym rynku, którego wartość w Polsce wynosi
                22 mld złotych. Segment piwa rzemieślniczego rośnie w szybkim tempie.
            </TypographySubscribe>
            <TypographySubscribe variant={"body1"} pt={'25px'}>Spółka prowadzi sprzedaż od 2017 roku i zwiększa ją dynamiczne każdego roku.
                Jest właścicielem nowoczesnego browaru, który został uruchomiony w 2022 roku.
            </TypographySubscribe>
            <TypographySubscribe variant={"body1"} pt={'25px'}>Zespół jest bardzo doświadczony. Doświadczenie członków zarządu wynosi 20 lat, a głównego piwowara 40 lat.
                Firma ma szeroki portfel produktów, które są nagradzane na prestiżowych konkursach.
            </TypographySubscribe>
            <TypographySubscribe variant={"body1"} pt={'25px'}>Spółka jest właścicielem znaków handlowych, a także
                opatentowanej innowacyjnej technologii produkcji.
            </TypographySubscribe>
            <TypographySubscribe variant={"body1"} pt={'25px'}>Piwa mają szeroką dystrybucję, która obejmuje sieci handlowe oraz sklepy i gastronomię.</TypographySubscribe>
            <Box sx={{display: 'grid', gridTemplateColumns: {xs: '1fr', lg: 'repeat(4, 1fr)'}, gridTemplateRows: {xs: 'repeat(4, 1fr)', lg: '1fr'}, margin: {xs: '0 auto', lg: 'auto'}, width: {xs: 'auto', lg: '100%'}, justifyContent: {xs: 'center', lg: 'space-between'}}} pt={6}>
                <ContainerImage sx={{gridColumn: {xs: '1/1', lg: '1/1'}, gridRow: {xs: '1/1', lg: '1/1'}}}>
                    <BoxImage>
                        <Box component="img" src={barrel} />
                    </BoxImage>
                    <DividerStyled />
                    <TypographyImage pt={2}><Box component={"span"} sx={{fontWeight: 600}}>Doświadczenie</Box><br/> w produkcji piwa</TypographyImage>
                </ContainerImage>
                <ContainerImage sx={{gridColumn: {xs: '1/1', lg: '2/3'}, gridRow: {xs: '2/3', lg: '1/1'}}}>
                    <BoxImage>
                        <Box component="img" src={beer} />
                    </BoxImage>
                    <DividerStyled />
                    <TypographyImage pt={2}><Box component={"span"} sx={{fontWeight: 600}}>Znajomość</Box><br/> branży</TypographyImage>
                </ContainerImage>
                <ContainerImage sx={{gridColumn: {xs: '1/1', lg: '3/4'}, gridRow: {xs: '3/4', lg: '1/1'}}}>
                    <BoxImage>
                        <Box component="img" src={growth} />
                    </BoxImage>
                    <DividerStyled />
                    <TypographyImage pt={2}><Box component={"span"} sx={{fontWeight: 600}}>Dynamiczny</Box><br/> rozwój</TypographyImage>
                </ContainerImage>
                <ContainerImage sx={{gridColumn: {xs: '1/1', lg: '4/5'}, gridRow: {xs: '4/5', lg: '1/1'}}}>
                    <BoxImage>
                        <Box component="img" src={hands} />
                    </BoxImage>
                    <DividerStyled />
                    <TypographyImage pt={2}><Box component={"span"} sx={{fontWeight: 600}}>Doświadczenie</Box><br/> biznesowe</TypographyImage>
                </ContainerImage>
            </Box>
        </Grid>
    )
}

export default Summary;

