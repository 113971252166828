import {Box, Divider, Grid, Typography} from "@mui/material";
import Costs from "../assets/images/targetOfInvest.png"
import Invest from "../assets/images/invest.png";

const Targets = (props: { marginRight: number, targetsRef: any}) => {
    return (
        <Grid container ref={props.targetsRef} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Cele emisji</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Typography variant={'h4'} pt={'50px'}>Celem emisji jest sfinansowanie dalszego rozwoju Spółki. Cele inewstycji obejmują dwa obszary:</Typography>
            <Box>
                <Box component={"ul"} sx={{margin: 0}}>
                    <Box component={"li"} mt={2} mb={1} sx={{fontWeight: 500, fontSize: '18px', lineHeight: '30px'}}>Rozbudowę możliwości produkcyjnych:</Box>
                    <ul>
                        <li>linia do rozlewu w puszki</li>
                        <li>urządzenia do rozlewu i mycia beczek keg</li>
                        <li>urządzenia do oszczędzania energii</li>
                        <li>tanki do fermentacji i leżakowania piwa</li>
                    </ul>
                </Box>
                <Box component={"ul"}>
                    <Box component={"li"} mt={2} mb={1} sx={{fontWeight: 500, fontSize: '18px', lineHeight: '30px'}}>Inwestycje rynkowe:</Box>
                    <ul>
                        <li>zakup wyposażenia do sklepów i gastronomii (lodówki, nalewaki, parasole)</li>
                        <li>rozwój nowej marki</li>
                    </ul>
                </Box>
                <Box component={"ul"}>
                    <Box component={"li"} mt={2} sx={{fontWeight: 500, fontSize: '18px'}}>Pokrycie kosztów emisji akcji</Box>
                </Box>
            </Box>
            <Box component={"img"} mt={6} src={Costs} sx={{maxWidth: '930px', width: '100%'}} />
            <Box component={"img"} mt={6} src={Invest} sx={{maxWidth: '930px', width: '100%'}} />
        </Grid>
    )
}

export default Targets;
