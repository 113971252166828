import {AppBar, Box, IconButton, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import FullHeader from "../../components/Header/FullHeader";
import MinHeader from "../../components/Header/MinHeader";
import NaszBrowar from "../../assets/images/naszbrowar.png";
import AccountIcon from "../../ui/icons/AccountIcon";
import MenuIcon from "../../ui/icons/MenuIcon";
import {useNavigate} from "react-router-dom";

const LogoHeader = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xl'));
    const navigate = useNavigate();

    const fullHeader = <AppBar position="relative" sx={{backgroundColor: "white", zIndex: 100, boxShadow: 'none'}} component={"header"}>
        <Toolbar sx={{ margin: "0 5%", padding: "56px 0"}}>
            <Box sx={{flex: 1, display: "flex", justifyContent: "center"}}>
                <Box component="img" sx={{maxHeight: "100px", maxWidth: '200px', cursor: "pointer"}} src={NaszBrowar} onClick={() => navigate('/')}/>
            </Box>
        </Toolbar>
    </AppBar>

    const minHeader = <AppBar position="relative" sx={{height: {xs: "55px", md: "110px", zIndex: 100}, backgroundColor: "white", maxWidth: "100%"}}
                              component={"header"}>
        <Toolbar variant="dense" sx={{ padding: "14px 5%", minHeight: "0px", maxWidth: "100%"}}>
            <Box component="img" src={NaszBrowar} />
            <IconButton onClick={() => {}}>
                <AccountIcon />
            </IconButton>
            <IconButton sx={{marginLeft: "27px"}} onClick={() => {}}>
                <MenuIcon />
            </IconButton>
        </Toolbar>
    </AppBar>

    return <>
        {matches ? fullHeader : fullHeader}
    </>
}

export default LogoHeader;