import Page from "../model/Page";
import {getRequest} from "./api";
import Settings from "../model/Settings";

export const getAllPages = async () => {
    const data = await getRequest('/wp/v2/pages?per_page=100', (error) => {});
    return Page.fromApiResponse(data);
}

export const getSettings = async () => {
    const data = await getRequest('/acf/v3/options/option', (error) => {});
    return Settings.fromApiResponse(data);
}
