import axios from "axios";

export const sendForm = async (data: any, file: File | null, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/ex/form" : "";

    const formData = new FormData();
    formData.append("isCompany", data.isCompany);
    formData.append("is_stakeholder", data.hasShares);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("country", data.country);
    formData.append("street", data.street);
    formData.append("postalCode", data.postalCode);
    formData.append("city", data.city);
    formData.append("buildingNumber", data.buildingNumber);
    formData.append("apartmentNumber", data.apartmentNumber);

    formData.append("sharesNumber", data.sharesNumber);
    formData.append("unitPrice", data.unitPrice);
    formData.append("totalPrice", (data.unitPrice * data.sharesNumber).toString());

    if(data.isCompany) {
        formData.append("companyName", data.companyName);
        formData.append("nip", data.nip);
        formData.append("regon", data.regon);
    } else {
        formData.append("firstName", data.firstName);
        formData.append("lastName", data.lastName);
        formData.append("idNumber", data.idNumber);
        formData.append("pesel", data.pesel);
    }

    if(file) formData.append("file", file);

    const config = {
        headers: {
            "Content-Type":'multipart/form-data'
        }
    }
    try {
        const response = await axios.post(url, formData, config)
        if (response.status != 200) {
            onError && onError("Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.");
        }
    } catch(error: any) {
        console.error(error.response? error.response : error);
        onError && onError("Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.");
    }
}

export const sendData = async (data: any,  onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/ex/record" : "";

    const formData = new FormData();
    formData.append("isCompany", data.isCompany);
    formData.append("is_stakeholder", data.hasShares);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("country", data.country);
    formData.append("street", data.street);
    formData.append("postalCode", data.postalCode);
    formData.append("city", data.city);
    formData.append("buildingNumber", data.buildingNumber);
    formData.append("apartmentNumber", data.apartmentNumber);

    formData.append("sharesNumber", data.sharesNumber);
    formData.append("unitPrice", data.unitPrice);
    formData.append("totalPrice", (data.unitPrice * data.sharesNumber).toString());

    if(data.isCompany) {
        formData.append("companyName", data.companyName);
        formData.append("nip", data.nip);
        formData.append("regon", data.regon);
    } else {
        formData.append("firstName", data.firstName);
        formData.append("lastName", data.lastName);
        formData.append("idNumber", data.idNumber);
        formData.append("pesel", data.pesel);
    }

    const config = {
        headers: {
            "Content-Type":'multipart/form-data'
        }
    }
    try {
        const response = await axios.post(url, formData, config)
        if (response.status != 200) {
            onError && onError("Nastąpił błąd przy wysyłaniu danych! Spróbuj ponownie później.");
        }
    } catch(error: any) {
        console.error(error.response? error.response : error);
        onError && onError("Nastąpił błąd przy wysyłaniu danych! Spróbuj ponownie później.");
    }
}

export const sendNewsletter = async (data: {}, onError: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/ex/newsletter" : "";
    let response = null
    const config = {
        headers: {
            'Content-type': 'application/json',
        }
    }
    try {
        response = await axios.post(url, data, config)
    } catch(error: any) {
        onError && onError('Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.')
    }
    return response
}
