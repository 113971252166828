import NaszBrowar from '../../assets/images/naszbrowar.png';
import {
    AppBar,
    Box,
    Toolbar,
    Grid,
    Typography,
    useTheme,
    IconButton,
    styled,
    Button,
    useMediaQuery
} from "@mui/material";
import {useContext} from "react";
import ContentContext from "../../store/content-context";
import InstagramLogo from '../../ui/icons/InstagramLogo';
import FacebookLogo from '../../ui/icons/FacebookLogo';
import bosses from '../../assets/images/bosses.png';
import FormStocks from "../FormStocks";

const TypographyMenu = styled(Typography)(({theme}) => ({
    textTransform: 'capitalize',
    letterSpacing: '.2px',
    whiteSpace: "nowrap",

    '&:hover': {
        cursor: 'pointer'
    }
}))

const FullHeader = (props: {marginRight: number, refers: any}) => {
    const {summaryRef, targetsRef, marketRef, historyRef, teamRef, productsRef, distributionRef, ownBreweryRef, financialResultsRef, documentsRef} = props.refers.current
    const {palette, breakpoints} = useTheme();
    const matches = useMediaQuery(breakpoints.up('lg'));
    const {setting} = useContext(ContentContext);

    const handleScroll = (ref: any) => {
        window.scrollTo({
            top: ref.offsetTop + 1100,
            left: 0,
            behavior: "smooth",
        });
    };

    const renderSubMenu = () => {
        return (
            <Box sx={{
                backgroundColor: palette.primary.light,
                borderTopLeftRadius: '50px',
                borderBottomLeftRadius: '50px',
                maxWidth: '1650px',
                width: '100%',
            }}>
                <Box sx={{
                    maxWidth: '1295px',
                    height: "107px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 99,
                    position: "relative",
                    margin: '0 auto'
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: 'nowrap',
                        width: '90%',
                        height: "100%"
                    }}>
                        <Grid item container flexDirection={"column"} pl={"60px"}>
                            <TypographyMenu variant="body2" mb={"20px"} onClick={() => {handleScroll(summaryRef.current)}}>Podsumowanie</TypographyMenu>
                            <TypographyMenu variant="body2" onClick={() => {handleScroll(productsRef.current)}}>Produkty</TypographyMenu>
                        </Grid>
                        <Grid item container flexDirection={"column"} pl={"60px"}>
                            <TypographyMenu variant="body2" mb={"20px"} onClick={() => {handleScroll(targetsRef.current)}}>Cele Emisji</TypographyMenu>
                            <TypographyMenu variant="body2" onClick={() => {handleScroll(distributionRef.current)}}>Dystrybucja</TypographyMenu>
                        </Grid>
                        <Grid item container flexDirection={"column"} pl={"60px"}>
                            <TypographyMenu variant="body2" mb={"20px"} onClick={() => {handleScroll(targetsRef.current)}}>Jak Kupić Akcje</TypographyMenu>
                            <TypographyMenu variant="body2" onClick={() => {handleScroll(ownBreweryRef.current)}}>Własny Browar</TypographyMenu>
                        </Grid>
                        <Grid item container flexDirection={"column"} pl={"60px"}>
                            <Grid item container justifyContent={"space-between"}>
                                <TypographyMenu variant="body2" mb={"20px"} onClick={() => {handleScroll(marketRef.current)}}>Rynek</TypographyMenu>
                                <TypographyMenu variant="body2" onClick={() => {handleScroll(historyRef.current)}}>Historia</TypographyMenu>
                            </Grid>
                            <TypographyMenu variant="body2" onClick={() => {handleScroll(financialResultsRef.current)}}>Wyniki Finansowe i Prognozy</TypographyMenu>
                        </Grid>
                        <Grid item container flexDirection={"column"} pl={"60px"}>
                            <TypographyMenu variant="body2" mb={"20px"}  onClick={() => {handleScroll(teamRef.current)}}>Zespół</TypographyMenu>
                            <TypographyMenu variant="body2" onClick={() => {handleScroll(documentsRef.current)}}>Dokumenty</TypographyMenu>
                        </Grid>
                    </Box>
                    <Box sx={{display: 'flex'}} ml={3}>
                        <IconButton sx={{'&:hover': {backgroundColor: 'transparent'}, width: '58px'}} target="_blank"
                                    href={setting.instagram}><InstagramLogo/></IconButton>
                        <IconButton sx={{'&:hover': {backgroundColor: 'transparent'}, width: '58px'}} target="_blank"
                                    href={setting.facebook}><FacebookLogo/></IconButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
            <Grid container justifyContent={"flex-end"} component={"header"}>
                <AppBar component={'div'} position="relative" sx={{backgroundColor: "white", zIndex: 100, boxShadow: 'none'}}>
                    <Toolbar sx={{ margin: "0 5%", padding: "56px 0"}}>
                        <Box sx={{flex: 1, display: "flex", justifyContent: "center"}}>
                            <Box component="img" id="banner-image" sx={{maxHeight: "100px", maxWidth: '200px'}} src={NaszBrowar} />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Grid container justifyContent={'flex-end'}>
                    {renderSubMenu()}
                </Grid>
                <Grid id="banner-container" item container justifyContent={"flex-end"} sx={{width: "100%", marginTop: "120px", position: "relative"}}>
                    {matches && <FormStocks />}
                    <Grid container item sx={{maxWidth: '1450px', margin: '0 auto'}} alignItems={'center'} justifyContent={'flex-end'}>
                        <Grid container item justifyContent={"flex-end"} sx={{maxWidth: '1066px'}}>
                            <Box component={'img'} src={bosses} sx={{width: '100%'}} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default FullHeader;
