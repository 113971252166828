import {
    Box,
    Button, CircularProgress,
    Divider,
    Grid,
    Link,
    Typography,
    useTheme
} from "@mui/material";
import {useContext, useRef, useState} from "react";
import BuySharesContext from "../../store/buy-shares-context";
import SnackbarContext from "../../store/snackbar-context";
import {FileUploadOutlined} from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import {sendForm} from "../../api/contact-api";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SixStep = () => {
    const {palette} = useTheme();
    const ctx = useContext(BuySharesContext);
    const {setMsg} = useContext(SnackbarContext);
    const [isSending, setIsSending] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);
    const hiddenFileInput = useRef<any>({});

    const handleSend = () => {
        if(isSending || sendSuccess) {
            return;
        }
        if (!ctx.hasData()) {
            setMsg({msg: "Uzupełnij formularz", severity: "warning"})
            return;
        } else if (!ctx.file) {
            setMsg({msg: "Załaduj podpisany formularz", severity: "warning"})
            return;
        }

        setIsSending(true);
        sendForm(ctx, ctx.file, (error) => {
            setMsg({msg: "Błąd przy wysyłaniu formularza."})
        })
            .then(r => setSendSuccess(true))
            .catch((error) => {
                setMsg({msg: "Błąd przy wysyłaniu formularza."})
                console.log(error);
            })
            .finally(() => setIsSending(false));
    }


    const handleUploadClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click()
        }
    };

    const handleUploadChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            ctx.setFile(fileUploaded);
        }
    };

    return <Grid item container id={"six-step"} sx={{
        backgroundColor: palette.primary.light,
        width: "100%",
        mt: "106px",
    }}>
        <Grid item container flexDirection={"column"} sx={{
            maxWidth: '1450px',
            margin: '0 auto',
            padding: '0 50px',
            position: 'relative',
        }}>
            <Typography variant={"h3"} mt={"87px"} mb={"35px"}>Podpisz formularz i odeślij</Typography>
            <Divider sx={{backgroundColor: palette.primary.dark, maxWidth: "1020px"}}/>
            <Typography mt={"30px"} sx={{fontSize: "20px !important", fontWeight: 600, maxWidth: "100%"}}>
                Po wygenerowaniu formularza podpisz formularz elektronicznie lub własnoręcznie i odeślij do nas.
            </Typography>
            <Grid item>
                <Typography mt={"30px"} sx={{fontSize: "20px !important", textDecoration: "underline", maxWidth: "100%"}}>
                    Elektronicznie
                </Typography>
                <Typography sx={{fontSize: "20px !important", maxWidth: "100%"}}>
                    Pobierz dokument, podpisz profilem zaufanym i załaduj plik na stronie lub odeślij mailem na adres
                    inwestorzy@naszbrowar.pl (w przypadku braku profilu zaufanego przejdź do sekcji FAQ)
                </Typography>
            </Grid>
            <Grid item>
                <Typography mt={"30px"} sx={{fontSize: "20px !important", maxWidth: "100%"}}
                            display={"inline"}>
                    Instrukcja:&nbsp;
                </Typography>
                <Link sx={{
                    fontSize: "20px !important",
                    fontWeight: 400,
                    overflowWrap: "break-word",
                    textDecoration: "none",
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontStyle: "italic",
                    maxWidth: "100%"
                }} href={"https://www.gov.pl/web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany"}
                      target={"_blank"}>
                    https://www.gov.pl/ web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany
                </Link>
            </Grid>
            <Grid item>
                <Typography mt={"30px"} sx={{fontSize: "20px !important", textDecoration: "underline", maxWidth: "100%"}}>
                    Papierowy formularz
                </Typography>
                <Typography sx={{fontSize: "20px !important", coursor: "pointer"}}>
                    Wyślij papierowy formularz z własnoręcznym podpisem na
                    adres Browar Za Miastem sp. z o.o. SKA, ul. Południowa 14, 62-080&nbsp;Rumianek
                </Typography>
            </Grid>
            <Grid item mt={"69px"}>
                {/*<Button sx={{*/}
                {/*    minWidth: {xs: "100%", md: '339px'},*/}
                {/*    textAlign: 'center',*/}
                {/*    mr: "35px",*/}
                {/*    height: "70px",*/}
                {/*    fontSize: '24px',*/}
                {/*    mb: "40px",*/}
                {/*    fontWeight: 600,*/}
                {/*    backgroundColor: palette.secondary.light,*/}
                {/*    borderRadius: 0*/}
                {/*}} variant='contained' onClick={handleDownloadForm}>Pobierz formularz</Button>*/}
                <Button
                    startIcon={ctx.file ? <CheckIcon color={"inherit"} fontSize={"large"}
                                                 sx={{fontSize: "35px !important", marginRight: "20px"}}/> :
                        <FileUploadOutlined color={"inherit"} fontSize={"large"}
                                            sx={{fontSize: "35px !important", marginRight: "20px"}}/>}
                    sx={{
                        minWidth: {xs: "100%", md: '339px'},
                        mb: "40px",
                        textAlign: 'center',
                        height: "70px",
                        fontSize: '24px',
                        fontWeight: 600,
                        backgroundColor: palette.secondary.light,
                        borderRadius: 0,
                    }} variant='contained' onClick={handleUploadClick}>
                    {ctx.file ? "Załadowano" : "Załaduj plik"}

                </Button>
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleUploadChange}
                    style={{display: 'none'}}
                />
            </Grid>
            <Button
                sx={{
                    width: {xs: "100%", md: '339px'},
                    textAlign: 'center',
                    mb: "50px",
                    height: "70px",
                    fontSize: '30px',
                    fontWeight: 600,
                    backgroundColor: palette.secondary.light,
                    borderRadius: 0,
                    position: "relative"
                }} variant='contained' onClick={() => handleSend()}>
                {!sendSuccess && (isSending ? <CircularProgress color={"secondary"}/> : "Wyślij")}
                {sendSuccess && "Wysłano"}
                {sendSuccess && !isSending && <Box sx={{position: "absolute", bottom: "0", left: "0", transform: "translateY(+120%)", backgroundColor: "#EDF7ED", display: "flex", alignItems: "center", padding: "10px"}}>
                    <CheckCircleOutlineIcon sx={{color: "#ACD7AB"}} fontSize={"large"}/>
                    <Typography variant={"h4"} sx={{color: "#91A691", ml: "10px", whiteSpace: "nowrap"}}>Dziękujemy za przesłanie formularza</Typography>
                </Box>}
            </Button>
        </Grid>
    </Grid>
}

export default SixStep;