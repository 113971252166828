import Block from "./Block";
import {getRelativeLink} from "../utils/string-tools";

class Page {
    link: string;
    title: string;
    blocks: Block[];

    constructor(link: string, title: string , blocks: Block[]) {
        this.link = link;
        this.title = title;
        this.blocks = blocks;
    }

    public static fromApiResponse(data: any) {
        const pages = [] as Page[];
        data.forEach((d: any) => {
            if(d.acf.blocks) {
                const blocks = d.acf.blocks.map((block: any) => new Block(block.acf_fc_layout, block));
                pages.push(new Page(getRelativeLink(d.link), d.title.rendered, blocks))
            }
        })
        return pages;
    }


    public menu() {
        let arr: any[] = [];
        this.blocks.map((block, index: number) => {
            arr.push(block.component)
        })
        return arr
    }
}

export default Page;
