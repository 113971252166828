import {Box, Divider, Grid, Typography} from "@mui/material";
import FinancialResultsImage from "../assets/images/financialResults.png";
import Assets from "../assets/images/assets.png";
import Predictions from '../assets/images/predictions.png';
import Faq from "./Faq";

const FinancialResults = (props: {marginRight: number, financialResultsRef: any}) => {
    return (
        <Grid container ref={props.financialResultsRef} component={'section'} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Wyniki finansowe i prognozy</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Box pt={4}>
                <Typography variant={'h4'} sx={{textDecoration: 'underline'}}>
                    Wyniki
                </Typography>
                <Typography variant={'body1'} pt={3}>
                    Sprzedaż i przychody Firmy rosną nieprzerwanie od 2017 roku. Sprzedaż rośnie głównie poprzez wzrost popularności marki, rozwój portfela produktów oraz rozszerzanie dystrybucji. W 2021 roku sprzedaliśmy około 1 milion 200 tysięcy półlitrowych butelek piwa (beczki zostały przeliczone na butelki).
                </Typography>
            </Box>
            <Box component={'img'} src={FinancialResultsImage} sx={{maxWidth: '930px', width: '100%'}} pt={4} />
            <Typography sx={{fontWeight: 300, fontSize: '16px!important'}} mt={3}>Źródło: Spółka, Dane finansowe.</Typography>
            <Box>
                <Typography variant={'body1'} pt={4}>
                    Firma osiągnęła dodatni wynik na sprzedaży w 2020 roku. Wynik finansowy za cały rok 2020 był ujemny za sprawą wysokich kosztów emisji akcji drogą crowdfundingu udziałowego.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    W latach 2020 – 2022 Spółka realizowała prace związane z budową i zakupem urządzeń produkcyjnych.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Inwestycja była finansowana ze środków własnych, kredytu inwestycyjnego oraz dotacji unijnej.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    W latach 2017 – 2020 spółka realizowała emisje akcji na finansowanie inwestycji.
                </Typography>
            </Box>
            <Box pt={8}>
                <Typography variant={'h4'}>
                    Suma bilansowa spółki rośnie z roku na rok i przekroczyła 12 mln. zł.
                </Typography>
                <Box component={'img'} src={Assets} sx={{maxWidth: '930px', width: '100%'}} pt={4} />
            </Box>
            <Box pt={6}>
                <Typography variant={'h4'} sx={{textDecoration: 'underline'}}>
                    Prognozy
                </Typography>
                <Typography variant={'h4'} pt={4}>
                    Prognoza finansowa została przygotowana przez Zarząd Spółki w oparciu o wyniki firmy oraz założenia z biznes planu Spółki. Dane zostały zweryfikowane na podstawie dostępnych wyników finansowych podobnych przedsiębiorstw z branży piwnej.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Dane finansowe za lata przyszłe są danymi wstępnymi i mogą ulec zmianie. Emitent nie gwarantuje osiągnięcia konkretnych wyników finansowych.
                </Typography>
                <Typography sx={{fontWeight: 300, fontSize: '16px!important'}} mt={3}>Źródło: Spółka, Dane finansowe.</Typography>
                <Box component={'img'} src={Predictions} sx={{maxWidth: '930px', width: '100%'}} pt={5} />
                <Typography sx={{fontWeight: 300, fontSize: '16px!important'}} mt={3}>Źródło: Spółka, Dane finansowe.</Typography>
                <Typography variant={'body1'} pt={2}>
                    Wzrost zobowiązań spółki na koniec roku 2021 oraz kolejnych latach jest ściśle związany z
                    prowadzoną inwestycją. Zobowiązania obejmują głównie kredyt inwestycyjny oraz zobowiązania z
                    tytułu dostaw urządzeń produkcyjnych. Część kredytu inwestycyjnego zostanie spłacona już w roku
                    2022 z pozyskanej dotacji (BGK, Kredyt na Innowacje Technologiczne). Planowana jest stopniowa
                    spłata zobowiązań począwszy od roku 2023.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Intencją Spółki jest wypłata dywidendy od roku 2024 w wysokości 50-100% zysku
                </Typography>
            </Box>
            <Faq />
        </Grid>
    )
}

export default FinancialResults
