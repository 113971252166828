import {Divider, Grid, Typography, Box} from "@mui/material";
import Building from '../assets/images/building.jpg';

const OwnBrewery = (props: {marginRight: number, ownBreweryRef: any}) => {
    return (
        <Grid container ref={props.ownBreweryRef} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Własny Browar</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Box pt={4}>
                <Typography variant={'body1'}>
                    Spółka posiada własny browar, który został uruchomiony w połowie 2022 roku. Moce produkcyjne wynoszą obecnie 11 tys. hl. rocznie, a docelowo wyniosą 30 tys. hl. Urządzenia są nowoczesne i umożliwiają produkcję piwa wysokiej jakości.
                </Typography>
                <Typography variant={'body1'} pt={3}>
                    Aktualnie rozpoczęło się przenoszenie produkcji z innych browarów do własnego zakładu. Celem jest zwiększenie elastyczności funkcjonowania, wzrost kontroli nad jakością produktu oraz obniżenie kosztów produkcji.
                </Typography>
            </Box>
            <Box component={'img'} src={Building} sx={{maxWidth: '930px', width: '100%'}} pt={6} />
        </Grid>
    )
}

export default OwnBrewery;
