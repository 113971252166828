import {Divider, Grid, Typography} from "@mui/material";

const Distribution = (props: {marginRight: number, distributionRef: any}) => {
    return (
        <Grid container ref={props.distributionRef} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Dystrybucja</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Typography variant={"body1"} pt={4}>
                Od początku istnienia Spółka stawia duży akcent na szeroką dostępność produktów. Obecnie piwa sprzedawane są w sklepach i punktach gastronomicznych na terenie całej Polski. Obejmuje to punkty niezależne oraz sklepy sieci: Auchan, Carrefour, Lidl, Duży Ben, Intermarche, Kaufland, Orlen, Spar, Stokrotka, Delio (Grupa Żabka Polska).
            </Typography>
        </Grid>
    )
}

export default Distribution
