import {createContext, ReactNode, useReducer} from "react";

type BuySharesContextType = {
    lockData: boolean,
    file: File | null,
    step: number,
    hasShares: boolean | undefined,
    unitPrice: number,
    sharesNumber: number | undefined,
    isCompany: boolean | undefined,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    idNumber: string,
    pesel: string,
    country: string,
    street: string,
    postalCode: string,
    city: string,
    companyName: string,
    nip: string,
    regon: string,
    buildingNumber: string,
    apartmentNumber: string,
    setLockData: (value: boolean) => void,
    setFile: (file: File | null) => void,
    setFirstName: (value: string) => void,
    setLastName: (value: string) => void,
    setEmail: (value: string) => void,
    setPhone: (value: string) => void,
    setIdNumber: (value: string) => void,
    setPesel: (value: string) => void,
    setCountry: (value: any) => void,
    setStreet: (value: string) => void,
    setPostalCode: (value: string) => void,
    setCity: (value: string) => void,
    setCompanyName: (value: string) => void,
    setNip: (value: string) => void,
    setRegon: (value: string) => void,
    setBuildingNumber: (value: string) => void,
    setApartmentNumber: (value: string) => void,
    setIsCompany: (isCompany: boolean) => void,
    setUnitPrice: (price: number) => void,
    setSharesNumber: (shares: number) => void,
    setHasShares: (hasShares: boolean | undefined) => void,
    setStep: (step: number) => void,
    hasData: () => boolean,
};

type ActionType = {
    type: string;
    value: any;
};

const SET_STEP = "setStep";
const SET_UNIT_PRICE = "setUnitPrice";
const SET_IS_COMPANY = "setIsCompany";
const SET_SHARES_NUMBER = "setSharesNumber";
const SET_HAS_SHARES = "setHasShares";

const SET_FIRST_NAME = "setFirstName";
const SET_LAST_NAME = "setLastName";
const SET_EMAIL = "setEmail";
const SET_PHONE = "setPhone";
const SET_ID_NUMBER = "setIdNumber";
const SET_PESEL = "setPesel";
const SET_COUNTRY = "setCountry";
const SET_STREET = "setStreet";
const SET_POSTAL_CODE = "setPostalCode";
const SET_CITY = "setCity";
const SET_LOCK_DATA = "setLockData";

const SET_COMPANY_NAME = "setCompanyName";
const SET_NIP = "setNIP";
const SET_REGON = "setRegon";
const SET_BUILDING_NUMBER = "setBuildingNumber";
const SET_APARTMENT_NUMBER = "setApartmentNumber";

const SET_FILE = "setFile";

const INITIAL_BUY_SHARES_STATE: BuySharesContextType = {
    lockData: false,
    file: null,
    step: 0,
    hasShares: undefined,
    unitPrice: 2.70,
    sharesNumber: undefined,
    isCompany: undefined,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    idNumber: "",
    pesel: "",
    country: "",
    street: "",
    postalCode: "",
    city: "",
    companyName: "",
    nip: "",
    regon: "",
    buildingNumber: "",
    apartmentNumber: "",
    setLockData: (value: boolean) => {},
    setFile: (file: File | null) => {},
    setFirstName: (value: string) => {},
    setLastName: (value: string) => {},
    setEmail: (value: string) => {},
    setPhone: (value: string) => {},
    setIdNumber: (value: string) => {},
    setPesel: (value: string) => {},
    setCountry: (value: string) => {},
    setStreet: (value: any) => {},
    setPostalCode: (value: string) => {},
    setCompanyName: (value: string) => {},
    setNip: (value: string) => {},
    setRegon: (value: string) => {},
    setBuildingNumber: (value: string) => {},
    setApartmentNumber: (value: string) => {},
    setCity: (value: string) => {},
    setIsCompany: (isCompany: boolean) => {},
    setSharesNumber: (shares: number) => {},
    setUnitPrice: (price: number) => {},
    setHasShares: (hasShares: boolean | undefined) => {},
    setStep: (step: number) => {},
    hasData: () => false,
};

const contentReducer = (state: BuySharesContextType, action: ActionType): BuySharesContextType => {
    switch (action.type) {
        case SET_STEP: {
            return {
                ...state,
                step: action.value,
            };
        }
        case SET_HAS_SHARES: {
            if(state.lockData) return state;
            return {
                ...state,
                hasShares: action.value,
            };
        }
        case SET_UNIT_PRICE: {
            if(state.lockData) return state;
            return {
                ...state,
                unitPrice: action.value,
            };
        }
        case SET_SHARES_NUMBER: {
            if(state.lockData) return state;
            return {
                ...state,
                sharesNumber: action.value,
            };
        }
        case SET_IS_COMPANY: {
            if(state.lockData) return state;
            return {
                ...state,
                isCompany: action.value,
            };
        }
        case SET_FIRST_NAME: {
            if(state.lockData) return state;
            return {
                ...state,
                firstName: action.value,
            };
        }
        case SET_LAST_NAME: {
            if(state.lockData) return state;
            return {
                ...state,
                lastName: action.value,
            };
        }
        case SET_EMAIL: {
            if(state.lockData) return state;
            return {
                ...state,
                email: action.value,
            };
        }
        case SET_PHONE: {
            if(state.lockData) return state;
            return {
                ...state,
                phone: action.value,
            };
        }
        case SET_ID_NUMBER: {
            if(state.lockData) return state;
            return {
                ...state,
                idNumber: action.value,
            };
        }
        case SET_PESEL: {
            if(state.lockData) return state;
            return {
                ...state,
                pesel: action.value,
            };
        }
        case SET_COUNTRY: {
            if(state.lockData) return state;
            return {
                ...state,
                country: action.value,
            };
        }
        case SET_STREET: {
            if(state.lockData) return state;
            return {
                ...state,
                street: action.value,
            };
        }
        case SET_POSTAL_CODE: {
            if(state.lockData) return state;
            return {
                ...state,
                postalCode: action.value,
            };
        }
        case SET_CITY: {
            if(state.lockData) return state;
            return {
                ...state,
                city: action.value,
            };
        }
        case SET_COMPANY_NAME: {
            if(state.lockData) return state;
            return {
                ...state,
                companyName: action.value,
            };
        }
        case SET_NIP: {
            if(state.lockData) return state;
            return {
                ...state,
                nip: action.value,
            };
        }
        case SET_REGON: {
            if(state.lockData) return state;
            return {
                ...state,
                regon: action.value,
            };
        }
        case SET_BUILDING_NUMBER: {
            if(state.lockData) return state;
            return {
                ...state,
                buildingNumber: action.value,
            };
        }
        case SET_APARTMENT_NUMBER: {
            if(state.lockData) return state;
            return {
                ...state,
                apartmentNumber: action.value,
            };
        }
        case SET_FILE: {
            return {
                ...state,
                file: action.value
            }
        }
        case SET_LOCK_DATA: {
            return {
                ...state,
                lockData: action.value
            }
        }
        default:
            return state;
    }
};

export const BuySharesContextProvider = ({children} : {children: ReactNode} ) => {
    const [ctx, dispatch] = useReducer(contentReducer, INITIAL_BUY_SHARES_STATE);

    const setStep = (step: number) => {dispatch({type: SET_STEP, value: step});};
    const setHasShares = (hasShares: boolean | undefined) => {dispatch({type: SET_HAS_SHARES, value: hasShares});};
    const setUnitPrice = (unitPrice: number) => {dispatch({type: SET_UNIT_PRICE, value: unitPrice});};
    const setSharesNumber = (sharesNumber: number) => {dispatch({type: SET_SHARES_NUMBER, value: sharesNumber});};
    const setIsCompany = (isCompany: boolean) => {dispatch({type: SET_IS_COMPANY, value: isCompany});};

    const setFirstName = (value: string) => {dispatch({type: SET_FIRST_NAME, value: value});};
    const setLastName = (value: string) => {dispatch({type: SET_LAST_NAME, value: value});};
    const setEmail = (value: string) => {dispatch({type: SET_EMAIL, value: value});};
    const setPhone = (value: string) => {dispatch({type: SET_PHONE, value: value});};
    const setIdNumber = (value: string) => {dispatch({type: SET_ID_NUMBER, value: value});};
    const setPesel = (value: string) => {dispatch({type: SET_PESEL, value: value});};
    const setCountry = (value: any) => {dispatch({type: SET_COUNTRY, value: value});};
    const setStreet = (value: string) => {dispatch({type: SET_STREET, value: value});};
    const setPostalCode = (value: string) => {dispatch({type: SET_POSTAL_CODE, value: value});};
    const setCity = (value: string) => {dispatch({type: SET_CITY, value: value});};

    const setCompanyName = (value: string) => {dispatch({type: SET_COMPANY_NAME, value: value});};
    const setNip = (value: string) => {dispatch({type: SET_NIP, value: value});};
    const setRegon = (value: string) => {dispatch({type: SET_REGON, value: value});};
    const setBuildingNumber = (value: string) => {dispatch({type: SET_BUILDING_NUMBER, value: value});};
    const setApartmentNumber = (value: string) => {dispatch({type: SET_APARTMENT_NUMBER, value: value});};

    const setFile = (value: File | null) => {dispatch({type: SET_FILE, value: value});};
    const setLockData = (value: boolean) => {dispatch({type: SET_LOCK_DATA, value: value});};

    const hasData = () => {
        const common = ctx.email !== "" && ctx.phone !== "" && ctx.country !== "" && ctx.street !== "" && ctx.postalCode !== "" && ctx.city !== "" && ctx.buildingNumber !== "";
        if(ctx.isCompany) {
            return common && ctx.companyName !== "" && ctx.nip !== "" && ctx.regon !== "";
        } else {
            return common && ctx.firstName !== "" && ctx.lastName !== "" && ctx.idNumber !== "" && ctx.pesel !== ""
        }
    }

    const contextValue: BuySharesContextType = {
        ...ctx,
        setFirstName: setFirstName,
        setLastName: setLastName,
        setEmail: setEmail,
        setPhone: setPhone,
        setIdNumber: setIdNumber,
        setPesel: setPesel,
        setCountry: setCountry,
        setStreet: setStreet,
        setPostalCode: setPostalCode,
        setCity: setCity,
        setCompanyName: setCompanyName,
        setNip: setNip,
        setRegon: setRegon,
        setBuildingNumber: setBuildingNumber,
        setApartmentNumber: setApartmentNumber,
        setIsCompany: setIsCompany,
        setSharesNumber: setSharesNumber,
        setUnitPrice: setUnitPrice,
        setStep: setStep,
        setHasShares: setHasShares,
        hasData: hasData,
        setFile: setFile,
        setLockData: setLockData
    };
    return <BuySharesContext.Provider value={contextValue}>{children}</BuySharesContext.Provider>;
};

const BuySharesContext = createContext<BuySharesContextType>({
    ...INITIAL_BUY_SHARES_STATE,
});

export default BuySharesContext;
