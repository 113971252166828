class Block{
    component: string;
    content: any;

    constructor(component: string, content: any) {
        this.component = component;
        this.content = content;
    }
}

export default Block;