import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import {getAllPages, getSettings} from "./api/content-api";
import ContentContext from "./store/content-context";
import {Route, Routes} from 'react-router-dom';
import ScrollToTop from "./pages/ScrollToTop";
import BuyShares from "./pages/BuyShares/BuyShares";
import MainPage from "./pages/MainPage";
import InfoBanner from "./pages/InfoBanner";

function App() {
    const {setPages, setSettings} = useContext(ContentContext);
    const [days, setDays] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const pages = await getAllPages();
            const settings = await getSettings();
            setDays(settings.settings.days);
            setPages([...pages]);
            setSettings(settings);
        }
        fetchData().catch(error => console.log(error));
    }, [])

    return (
        <ScrollToTop>
            <Routes>
                <Route element={<MainPage/>} path={"/"}/>
                {(days > 0) && <Route key={"buy-shares"} path={"/kup-akcje"} element={<BuyShares/>}/>}
                <Route key={"default"} path={"*"} element={<MainPage/>}/>
            </Routes>
            {(days <= 0) && <InfoBanner/>}
        </ScrollToTop>
    );
}

export default App;
