import {Alert, Grid, Snackbar, useMediaQuery, useTheme} from "@mui/material";
import FormStocks from "../components/FormStocks";
import FormStocksMin from "../components/FormStocksMin";
import Summary from "../components/Summary";
import Targets from "../components/Targets";
import Market from "../components/Market";
import History from "../components/History";
import Team from "../components/Team";
import Products from "../components/Products";
import Distribution from "../components/Distribution";
import OwnBrewery from "../components/OwnBrewery";
import FinancialResults from "../components/FinancialResults";
import Documents from "../components/Documents";
import MainLayout from "../layout/MainLayout";
import {useContext, useRef} from "react";
import SnackbarContext from "../store/snackbar-context";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";

const marginRight: number = 12;

const MainPage = () => {
    const summaryRef = useRef<null | HTMLDivElement>(null);
    const targetsRef = useRef<null | HTMLDivElement>(null);
    const marketRef = useRef<null | HTMLDivElement>(null);
    const historyRef = useRef<null | HTMLDivElement>(null);
    const teamRef = useRef<null | HTMLDivElement>(null);
    const productsRef = useRef<null | HTMLDivElement>(null);
    const distributionRef = useRef<null | HTMLDivElement>(null);
    const ownBreweryRef = useRef<null | HTMLDivElement>(null);
    const financialResultsRef = useRef<null | HTMLDivElement>(null);
    const documentsRef = useRef<null | HTMLDivElement>(null);
    const refs = useRef({summaryRef, targetsRef, marketRef, historyRef, teamRef, productsRef, distributionRef, ownBreweryRef, financialResultsRef, documentsRef});
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));
    const {msg, severity, setMsg} = useContext(SnackbarContext);

    return (
        <MainLayout>
            <Header refers={refs} marginRight={marginRight} />
            <Grid container component={"main"} justifyContent={'center'} alignItems={'center'} sx={{maxWidth: '1450px', margin: '0 auto', position: 'relative'}}>
                {!matches && <FormStocksMin />}
                <Grid container justifyContent={"flex-end"}>
                    <Summary summaryRef={summaryRef} marginRight={marginRight} />
                    <Targets targetsRef={targetsRef} marginRight={marginRight} />
                    <Market marketRef={marketRef}  marginRight={marginRight} />
                    <History historyRef={historyRef} marginRight={marginRight} />
                    <Team teamRef={teamRef} marginRight={marginRight} />
                    <Products productsRef={productsRef} marginRight={marginRight} />
                    <Distribution distributionRef={distributionRef} marginRight={marginRight} />
                    <OwnBrewery ownBreweryRef={ownBreweryRef} marginRight={marginRight} />
                    <FinancialResults financialResultsRef={financialResultsRef} marginRight={marginRight} />
                    <Documents documentsRef={documentsRef} marginRight={marginRight} />
                </Grid>
                <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={msg != ""} onClose={() => setMsg({msg: ""})}>
                    <Alert severity={severity}>{msg}</Alert>
                </Snackbar>
            </Grid>
            <Footer />
        </MainLayout>
    )
}

export default MainPage;
