class BeerSettings {
    facebook: string;
    instagram: string;
    amount: string;
    amountOfShares: string;
    days: number;
    investors: string;
    sharePrice: string;
    sharePriceForStakeholders: string;

    private constructor(facebook: string, instagram: string, amount: string, amountOfShares: string, days: number, investors: string, sharePrice: string, sharePriceForStakeholders: string) {
        this.facebook = facebook;
        this.instagram = instagram;
        this.amount = amount;
        this.amountOfShares = amountOfShares;
        this.days = days < 0 ? 0 : days;
        this.investors = investors;
        this.sharePrice = sharePrice;
        this.sharePriceForStakeholders =sharePriceForStakeholders
    }

    public static fromApiResponse({acf}: any) {
        return new BeerSettings(
            acf.facebook,
            acf.instagram,
            acf.amount,
            acf.amountOfShares,
            acf.days,
            acf.investors,
            acf.sharePrice,
            acf.sharePriceForStakeholders
        )
    }

}

export default BeerSettings;
