import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useContext, useState} from "react";
import BuySharesContext from "../../store/buy-shares-context";
import CustomCheckbox from "./CustomCheckbox";
import SnackbarContext from "../../store/snackbar-context";

const FirstStep = () => {
    const {palette, breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const {setStep, setHasShares, hasShares} = useContext(BuySharesContext);
    const {setMsg} = useContext(SnackbarContext);
    const [error, setError] = useState(false);

    const handleGoNext = () => {
        if(hasShares === undefined) {
            setError(true);
            setMsg({msg: "Wybierz jedną z opcji", severity: "warning"})
        } else {
            setError(false);
            setStep(1);
        }
    }

    const handleOnChange = (value: boolean) => {
        setHasShares(value);
        setError(false);
    }

    return <Grid item container sx={{
        backgroundColor: palette.primary.light,
        width: "100%",
        mt: "106px",
    }}>
        <Grid item container flexDirection={"column"} sx={{
            maxWidth: '1450px',
            margin: '0 auto',
            padding: '0 50px',
            position: 'relative',
        }}>
            <Typography variant={"h3"} mt={"87px"} mb={"35px"}>Odpowiedz czy posiadasz akcje Browaru poprzednich
                emisji?</Typography>
            <Divider sx={{backgroundColor: palette.primary.dark, maxWidth: "1020px"}}/>
            <Grid item container>
                <FormGroup sx={{flexDirection: "row", mt: "64px", mb: "78px"}}>
                    <FormControlLabel control={<CustomCheckbox isError={error} checkboxProps={{
                        checked: hasShares !== undefined && hasShares,
                        onChange: () => handleOnChange(true)
                    }}/>}
                                      label={"Tak"} sx={{
                                          pl: {xs: "10px", sm: 0},
                        "& span": {
                            color: "black",
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                            fontSize: "26px"
                        }}}/>
                    <FormControlLabel control={<CustomCheckbox isError={error} checkboxProps={{
                        checked: hasShares !== undefined && !hasShares,
                        onChange: () => handleOnChange(false)
                    }}/>}
                                      label={"Nie"} sx={{
                                          ml: isMobile ? 0 : "74px",
                        "& span": {
                            color: "black",
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                            fontSize: "26px"
                        },
                    }}/>
                </FormGroup>
            </Grid>
            <Button sx={{
                maxWidth: '339px',
                m: "0 0 64px 0",
                textAlign: 'center',
                height: "70px",
                fontSize: '30px',
                fontWeight: 600,
                backgroundColor: palette.secondary.light,
                borderRadius: 0
            }} variant='contained' onClick={() => handleGoNext()}>Dalej</Button>
        </Grid>
    </Grid>
}

export default FirstStep;