import {Box, IconButton, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useState} from "react";

const InfoBanner = () => {
    const {palette} = useTheme();
    const [open, setOpen] = useState(true);

    return <>{open &&<Box sx={{position: "fixed", width: "100%", bottom: "5%", left: 0, zIndex: 500}}>
        <Box sx={{margin: "0 auto", maxWidth: "95%", backgroundColor: palette.secondary.light, display: "flex", alignItems: "center", justifyContent:"space-around", }}>
            <Box sx={{margin: "3%"}}>
                <Typography variant={"h5"} display={"inline"} color={palette.primary.light}>Niniejszym wyrażam zgodę na treść </Typography>
                <Link href={"/regulamin.pdf"} target="_blank" download sx={{display: "inline", color: '#000', fontSize: '18px', fontWeight: 600, textDecoration: 'none'}}>
                    Regulamin
                </Link>
                <Typography variant={"h5"} display={"inline"} color={palette.primary.light}> i </Typography>
                <Link href={"/polityka-prywatności.pdf"} download target="_blank" sx={{display: "inline", color: '#000', fontSize: '18px', fontWeight: 600, textDecoration: 'none'}}>
                    Informacje o przetwarzaniu danych osobowych
                </Link>
                <Typography variant={"h5"} display={"inline"} color={palette.primary.light}> niniejszej Strony. Akceptując Regulamin w szczególności wyrażam zgodę na zawieranie z Emitentem wszelkich umów oraz składanie Emitentowi wszelkich oświadczeń woli dotyczących Oferty Publicznej (w tym zawarcie umowy objęcia akcji oraz złożenie formularza zapisu na akcje) w postaci elektronicznej w formie dokumentu elektronicznego, w zakresie i na zasadach określonych w art. 6 ustawy z dnia 29 lipca 2005 r. o ofercie publicznej i warunkach wprowadzania instrumentów finansowych do zorganizowanego systemu obrotu oraz o spółkach publicznych.</Typography>
            </Box>
            <IconButton size="large" sx={{width: "50px", height: "50px", marginRight: "3%"}} onClick={() => setOpen(false)}>
                <CloseIcon fontSize="large" sx={{color: palette.primary.light}}/>
            </IconButton>
        </Box>
    </Box>}</>
}

export default InfoBanner;