import {createContext, ReactNode, useReducer} from "react";
import Page from "../model/Page";
import BeerSettings from "../model/BeerSettings";
import Settings from "../model/Settings";

type ContentContextType = {
    pages: [],
    menu: [],
    setting: BeerSettings,
    setPages: (pages: Page[]) => void,
    setMenu: (menu: []) => void,
    setSettings: (settings: Settings) => void,
};

type ActionType = {
    type: string;
    value?: any;
};

const SET_PAGES = "setPages";
const SET_MENU = "setMenu";
const SET_SETTINGS = "setSettings";

const INITIAL_CONTENT_STATE: ContentContextType = {
    pages: [],
    menu: [],
    setting: {} as BeerSettings,
    setPages: (pages: Page[]) => {},
    setMenu: (menu: []) => {},
    setSettings: (settings: Settings) => {},
};

const contentReducer = (state: ContentContextType, action: ActionType): ContentContextType => {
    switch (action.type) {
        case SET_PAGES: {
            return {
                ...state,
                pages: action.value,
            };
        }
        case SET_MENU: {
            return {
                ...state,
                menu: action.value,
            };
        }
        case SET_SETTINGS: {
            return {
                ...state,
                setting: action.value.settings,
            };
        }
        default:
            return state;
    }
};

export const ContentContextProvider = ({children} : {children: ReactNode} ) => {
    const [contentState, dispatch] = useReducer(contentReducer, INITIAL_CONTENT_STATE, undefined);

    const setPages = (pages: Page[]) => {dispatch({type: SET_PAGES, value: pages});};
    const setMenu = (menu: []) => {dispatch({type: SET_MENU, value: menu});};
    const setSettings = (settings: Settings) => {dispatch({type: SET_SETTINGS, value: settings});};

    const contextValue: ContentContextType = {
        ...contentState,
        setPages: setPages,
        setMenu: setMenu,
        setSettings: setSettings,
    };
    return <ContentContext.Provider value={contextValue}>{children}</ContentContext.Provider>;
};

const ContentContext = createContext<ContentContextType>({
    ...INITIAL_CONTENT_STATE,
});

export default ContentContext;
