import {ReactNode} from "react";

const MainLayout = ({children}: {children: ReactNode}) => {

    return (
        <>
                {children}
        </>
    );
}

export default MainLayout;
