import {Box, IconButton, Link, Typography, useTheme} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useState} from "react";

const FormInfoBanner = () => {
    const {palette} = useTheme();
    const [open, setOpen] = useState(true);

    return <>{open &&<Box sx={{position: "fixed", width: "100%", bottom: "5%", left: 0, zIndex: 5000}}>
        <Box sx={{margin: "0 auto", maxWidth: "95%", backgroundColor: palette.secondary.light, display: "flex", alignItems: "center", justifyContent:"space-around", }}>
            <Box sx={{margin: "3%"}}>
                <Typography variant={"h3"} color={palette.primary.light} sx={{mb: 1}}>Zapisz formularz i wróć, aby dokonać płatności </Typography>
                {/*<Typography variant={"h5"} display={"inline"} color={palette.primary.light} sx={{fontWeight: 700}}>Elektronicznie: </Typography>*/}
                {/*<Typography variant={"h5"} display={"inline"} color={palette.primary.light}>Pobierz dokument, podpisz profilem zaufanym i załaduj plik na stronie lub odeślij mailem na adres inwestorzy@naszbrowar.pl (w przypadku braku profilu zaufanego przejdź do sekcji FAQ)</Typography>*/}
                {/*<br/>*/}
                {/*<Typography variant={"h5"} display={"inline"} color={palette.primary.light}>Instrukcja: </Typography>*/}
                {/*<Link sx={{*/}
                {/*    overflowWrap: "break-word",*/}
                {/*    textDecoration: "none",*/}
                {/*}} href={"https://www.gov.pl/web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany"}*/}
                {/*      target={"_blank"}>*/}
                {/*    <Typography variant={"h5"} display={"inline"} color={palette.primary.light}>*/}
                {/*        https://www.gov.pl/ web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany*/}
                {/*    </Typography>*/}
                {/*</Link>*/}
                {/*<br/>*/}
                {/*<Typography variant={"h5"} display={"inline"} color={palette.primary.light} sx={{fontWeight: 700}}>Papierowy formularz: </Typography>*/}
                {/*<Typography variant={"h5"} display={"inline"} color={palette.primary.light}>Wyślij papierowy formularz z własnoręcznym podpisem na adres Browar za Miastem zp. z o.o. SKA, ul. Południowa 14, 62-080 Rumianek</Typography>*/}
            </Box>
            <IconButton size="large" sx={{width: "50px", height: "50px", marginRight: "3%"}} onClick={() => setOpen(false)}>
                <CloseIcon fontSize="large" sx={{color: palette.primary.light}}/>
            </IconButton>
        </Box>
    </Box>}</>
}

export default FormInfoBanner;