import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ContentContextProvider} from "./store/content-context";
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider, responsiveFontSizes} from "@mui/material";
import {SnackbarContextProvider} from "./store/snackbar-context";
import {BuySharesContextProvider} from "./store/buy-shares-context";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

let theme = createTheme({
    palette: {
        primary: {
            // main: "#857874",
            main: "#351202",
            light: "#F7F7F7",
            dark: "#707070",
        },
        secondary: {
            light: "#9ba037",
            main: "#C2B49D",
            dark: '#424242'
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: [
            'Montserrat', 'sans-serif'
        ].join(','),
        body1: {
            fontSize: "18px",
            fontWeight: 400,
        },
        body2: {
            fontSize: "16px",
            fontWeight: 500,
        },
        h1: {
            fontSize: "32px",
            fontWeight: 600,
        },
        h2: {
            fontSize: "41px",
            fontWeight: 600,
        },
        h3: {
            fontSize: "26px",
            fontWeight: 600,
        },
        h4: {
            fontSize: "18px",
            fontWeight: 600,
        },
        h5: {
            fontSize: "17px",
            fontWeight: 500,
        },
        h6: {
            fontSize: "17px",
            fontWeight: 300,
        }
    },
});

theme = responsiveFontSizes(theme);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <SnackbarContextProvider>
                <ContentContextProvider>
                    <BuySharesContextProvider>
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                    </BuySharesContextProvider>
                </ContentContextProvider>
            </SnackbarContextProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
