import axios from "axios";

export const getSignature = async (data: {}, onError: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/ex/sign" : "";
    let response = null

    const config = {
        headers: {
            'Content-type': 'application/json',
        }
    }
    try {
        response = await axios.post(url, data, config)
        response = response.data.data;
    } catch(error: any) {
        onError && onError('Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.')
    }
    return response;
}