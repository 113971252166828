import {useContext, useEffect, useState} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {Box, Grid} from "@mui/material";
import BuySharesContext from "../../store/buy-shares-context";

const title = "Formularz zapisu na akcje „BROWAR ZA MIASTEM SPÓŁKA Z OGRANICZONĄ\n" +
    "ODPOWIEDZIALNOŚCIĄ” SPÓŁKA KOMANDYTOWO-AKCYJNA"

const firstP = "Niniejszy dokument stanowi zapis na akcje zwykłe imienne serii L o wartości nominalnej 0,10 zł\n" +
    "każda spółki „Browar za Miastem spółka z ograniczoną odpowiedzialnością” Spółka Komandytowo-\n" +
    "Akcyjna z siedzibą w Rumianku, ul. Południowa 14, 62-080 Rumianek, wpisanej do rejestru\n" +
    "przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań – Nowe\n" +
    "Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem\n" +
    "KRS 0000609600, NIP 7811925913, REGON 364034362, o kapitale zakładowym 1.420.000,00 zł\n" +
    "(„Emitent”, „Spółka”), oferowane w drodze oferty publicznej, wyemitowane na podstawie uchwały\n" +
    "Nr 11 Walnego Zgromadzenia Spółki w sprawie podwyższenia kapitału zakładowego Spółki w\n" +
    "drodze emisji akcji serii L, wyłączenia prawa poboru dotychczasowych akcjonariuszy Spółki i\n" +
    "zmiany Statutu Spółki, podjętej w dniu 4 sierpnia 2022 roku, na podstawie której kapitał zakładowy\n" +
    "Spółki zostaje podwyższony z kwoty 1.420.000,00 zł (jeden milion czterysta dwadzieścia tysięcy\n" +
    "złotych), do kwoty nie niższej niż 1.440.000,00 zł (jeden milion czterysta czterdzieści tysięcy\n" +
    "złotych) i nie wyższej, niż 1.580.000,00 zł (jeden milion pięćset osiemdziesiąt tysięcy złotych), tj. o\n" +
    "kwotę nie niższą, niż 20.000,00 zł (dwadzieścia tysięcy złotych) i nie wyższą, niż 160.000,00 zł (sto\n" +
    "sześćdziesiąt tysięcy złotych), w drodze emisji nie mniej, niż 200.000 (dwieście tysięcy) i nie\n" +
    "więcej, niż 1.600.000 (jeden milion sześćset tysięcy) akcji zwykłych imiennych serii L, o wartości\n" +
    "nominalnej 0,10 zł (dziesięć groszy) każda akcja, oznaczonych numerami od 000 000 1 do nie\n" +
    "większego, niż 1 600 000, oferowanych z wyłączeniem prawa poboru dotychczasowych\n" +
    "akcjonariuszy."

const secondP = "Ewentualny zwrot środków nastąpi na rachunek, z którego środki zostały przelane na rachunek\n" +
    "bankowy Spółki. Wszelkie konsekwencje wynikające z nieprawidłowego wypełnienia formularza\n" +
    "zapisu ponosi Subskrybent. Konsekwencją niepełnego bądź nieprawidłowego określenia danych\n" +
    "dotyczących subskrybenta może być nieterminowy zwrot wpłaconych środków. Zwrot wpłaty\n" +
    "następuje bez jakichkolwiek odsetek i odszkodowań."

const thirdP = "Niniejszym oświadczam, że zapoznałam(em) się z treścią dokumentu obejmującego informacje o\n" +
    "Emitencie i warunkach oraz zasadach oferty, że treść ta jest dla mnie zrozumiała oraz że akceptuję\n" +
    "warunki oferty publicznej akcji serii L Emitenta. Oświadczam, że zapoznałem się z treścią Statutu\n" +
    "Spółki oraz wyrażam zgodę na jego treść.\n" +
    "Zgadzam się zarówno na przydzielenie mi akcji serii L Emitenta, jak i ich nieprzydzielenie, przy\n" +
    "czym nieprzydzielenie akcji serii L Emitenta lub przydzielenie ich w liczbie mniejszej może nastąpić\n" +
    "wyłącznie w następstwie zastosowania zasad przydziału opisanych w wezwaniu do zapisywania się\n" +
    "akcje serii L Emitenta.\n" +
    "Podmiotem upoważnionym do przyjmowania zapisów i wpłat na akcje jest tylko i wyłącznie Spółka."

type Props = {
    close: () => void,
}

const StakeHolderForm = ({close}: Props) => {
    const ctx = useContext(BuySharesContext);
    const [generate, setGenerate] = useState(false);

    useEffect(() => {
        if (!ctx.hasData()) {
            return;
        }
        const input = document.getElementById('divToPrint');
        if(input && generate) {
            html2canvas(input)
                .then((canvas) => {
                    var imgData = canvas.toDataURL("image/jpeg",1);
                    var pdf = new jsPDF("p", "mm", "a4");
                    var pageWidth = pdf.internal.pageSize.getWidth();
                    var pageHeight = pdf.internal.pageSize.getHeight();
                    var imageWidth = canvas.width;
                    var imageHeight = canvas.height;

                    var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
                    pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
                    pdf.save("Formularz zapisu na akcje Nasz Browar.pdf");
                    close();
                });
        } else {
            setGenerate(true);
        }
    },[generate])

    const getAddress = () => {
        if(ctx.apartmentNumber != "") {
            return `${ctx.street} ${ctx.buildingNumber}/${ctx.apartmentNumber}, ${ctx.postalCode} ${ctx.city}, ${ctx.country}`
        } else {
            return `${ctx.street} ${ctx.buildingNumber}, ${ctx.postalCode} ${ctx.city}, ${ctx.country}`
        }

    }
    return (
            <Box id={"divToPrint"} sx={{
                maxWidth: '210mm',
                minWidth: '210mm',
                padding: '15mm',
                backgroundColor: "white",
            }}>
                <Grid item container flexDirection={"column"} sx={{maxWidth: "100%", lineHeight: 1.5}}>
                    <Box component={"p"} color={"black"} sx={{fontWeight: 600, fontSize: "16px"}}>{title}</Box>
                    <Box component={"p"} color={"black"} sx={{fontSize: "14px"}}>{firstP}</Box>
                    <Box component={"ol"} sx={{fontSize: "14px"}}>
                        <Box component={"li"}>Dane subskrybenta</Box>
                        <Box component={"ol"} type={"a"}>
                            <Box component={"li"}>{ctx.isCompany ? "Firma:" : "Imię i nazwisko:"} {ctx.isCompany ? ctx.companyName : `${ctx.firstName} ${ctx.lastName}`}</Box>
                            <Box component={"li"}>{ctx.isCompany ? "Adres siedziby:" : "Adres:"} {getAddress()}</Box>
                            <Box component={"li"}>{ctx.isCompany ? "REGON/NIP:" : "PESEL:"} {ctx.isCompany ? `${ctx.regon}/${ctx.nip}` : ctx.pesel}</Box>
                            <Box component={"li"}>Adres email: {ctx.email}</Box>
                        </Box>
                        <Box component={"li"}>Liczba subskrybowanych akcji zwykłych na imiennych serii L Emitenta: {ctx.sharesNumber}</Box>
                        <Box component={"li"}>Wysokość dokonanej wpłaty na akcje w zł: {ctx.sharesNumber! * ctx.unitPrice}</Box>
                        <Box component={"li"}>Forma wpłat: przelew na rachunek bankowy Emitenta numer: 39 1050 1520 1000 0090 8100
                            2967 w tym za pośrednictwem podmiotu świadczącego usługi płatnicze, tj. Krajowy Integrator
                            Płatności S.A. z siedzibą w Poznaniu, przy ul. ul. Pl. Władysława Andersa 3, 61-894 Poznań,
                            NIP: 7773061579, przy czym płatność powinna być dokonana z rachunku bankowego, którego
                            posiadaczem jest Subskrybent lub z wykorzystaniem karty płatniczej wystawionej na
                            Subskrybenta.
                        </Box>
                    </Box>
                    <Box component={"p"} sx={{textDecoration: "underline", fontSize: "14px", fontWeight: 600}}>Uwaga:</Box>
                    <Box component={"p"} color={"black"} sx={{fontSize: "14px"}}>{secondP}</Box>
                    <Box component={"p"} sx={{textDecoration: "underline", fontWeight: 600, fontSize: "14px"}}>Oświadczenie osoby składającej zapis:</Box>
                    <Box component={"p"} color={"black"} sx={{fontSize: "14px"}}>{thirdP}</Box>
                    <Grid item container sx={{width: "80%", margin: "auto"}} justifyContent={"space-between"}>
                        <Box component={"p"} color={"black"} sx={{fontSize: "14px", width: "50mm", height: "20mm", borderBottom: "1px solid black", textAlign: "center"}}>Spółka</Box>
                        <Box component={"p"} color={"black"} sx={{fontSize: "14px", width: "50mm", height: "20mm", borderBottom: "1px solid black", textAlign: "center"}}>Subskrybent</Box>
                    </Grid>
                </Grid>
            </Box>
    )
}

export default StakeHolderForm;
