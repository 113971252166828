import {
    Box,
    Button,
    Divider,
    Grid, InputLabel,
    styled,
    TextField,
    Typography, useMediaQuery,
    useTheme
} from "@mui/material";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import BuySharesContext from "../../store/buy-shares-context";
import SnackbarContext from "../../store/snackbar-context";
import ContentContext from "../../store/content-context";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

export const StyledField = styled(TextField)({
    '& .MuiInputBase-input': {
        backgroundColor: "white",
        border: "1px solid black",
        fontWeight: 600,
        borderRadius: 0,
        maxWidth: "322px",
        minHeight: "86px",
        fontSize: "40px",
        textAlign: "center",

        '@media (max-width: 768px)': {
            maxWidth: "100%"
        }
    },

    '& .Mui-error': {
        border: "5px solid red",
    },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: "1px solid black",
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: "1px solid black",
            borderRadius: 0,
        },
        '&.Mui-focused fieldset': {
            border: "1px solid black",
            borderRadius: 0,
        },
    },
})

const StyledLabel = styled(InputLabel)({
    fontWeight: 600,
    color: "black",
    paddingTop: "25px",
    fontFamily: "Montserrat",
    fontSize: "24px !important",
    whiteSpace: "normal",
    height: "auto",
})

const SecondStep = () => {
    const {palette, breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const {setStep, sharesNumber, setSharesNumber, unitPrice, hasShares, setUnitPrice} = useContext(BuySharesContext);
    const {setting} = useContext(ContentContext);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [totalAmount, setTotalAmount] = useState<number|undefined>(undefined);

    const handleGoNext = () => {
        if((sharesNumber === undefined) || (sharesNumber < 200)) {
            setError(true);
            setErrorMsg(true);
        } else {
            setError(false);
            setErrorMsg(false);
            setStep(2);
        }
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        const value = Number(event.target.value);
        if(!isNaN(value)) {
            setSharesNumber(value);
        }
    }

    const formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
    });

    useEffect(() => {
        if(error) {
            setTimeout(() => {
                setErrorMsg(false);
            }, 3000);
        }
    }, [error]);

    useEffect(() => {
        if(hasShares) {
            setUnitPrice(Number(setting.sharePriceForStakeholders))
        } else {
            setUnitPrice(Number(setting.sharePrice))
        }
    }, [hasShares])

    useEffect(() => {
        if(sharesNumber && unitPrice) {
            setTotalAmount( sharesNumber * unitPrice)
        } else {
            setTotalAmount(undefined)
        }
    }, [sharesNumber])

    return <Grid item container id="second-step" sx={{
        backgroundColor: palette.primary.light,
        width: "100%",
        mt: "106px",
    }}>
        <Grid item container flexDirection={"column"} sx={{
            maxWidth: '1450px',
            margin: '0 auto',
            padding: '0 50px',
            position: 'relative',
        }}>
            <Typography variant={"h3"} mt={"87px"} mb={"30px"} sx={{fontWeight: "400"}}>Określ ile chcesz zainwestować</Typography>
            <Typography variant={"h3"} mb={"35px"}>Wybierz liczbę akcji do zakupu</Typography>
            <Divider sx={{backgroundColor: palette.primary.dark, maxWidth: "1020px"}}/>
            <Grid item container mt={"40px"} mb={"66px"} alignItems={isMobile ? "start" : "center"}>
                <Grid item xs={12} sm={3} sx={{position: "relative"}}>
                    <StyledField id={"amount"} error={error} value={sharesNumber} onChange={(event) => handleOnChange(event)}/>
                    <StyledLabel htmlFor={"amount"}>Ilość akcji</StyledLabel>
                    {errorMsg && <Box sx={{position: "absolute", bottom: "0", left: "0", backgroundColor: "#FFF4E5", display: "flex", alignItems: "center", padding: "10px"}}>
                        <ReportProblemOutlinedIcon sx={{color: "#FFCB91"}} fontSize={"large"}/>
                        <Typography variant={"h4"} sx={{color: "#87643A", ml: "10px", whiteSpace: "nowrap"}}>Minimalna liczba akcji to 200</Typography>
                    </Box>}
                </Grid>
                <Grid item container xs={12} sm={1} justifyContent={"center"} alignItems={"center"}>
                    <Typography variant={"h3"} mb={"35px"} sx={{fontSize: "40px !important"}}>X</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledField id={"unit-price"} value={formatter.format(unitPrice)}/>
                    <StyledLabel htmlFor={"unit-price"}>Cena akcji</StyledLabel>
                </Grid>
                <Grid item container xs={12} sm={1} justifyContent={"center"} alignItems={"center"}>
                    <Typography variant={"h3"} mb={"35px"} sx={{fontSize: "40px !important"}}>=</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledField id={"price"} value={totalAmount && formatter.format(totalAmount)}/>
                    <StyledLabel htmlFor={"price"}>Suma</StyledLabel>
                </Grid>
            </Grid>
            <Button sx={{
                maxWidth: '339px',
                m: "0 0 64px 0",
                textAlign: 'center',
                height: "70px",
                fontSize: '30px',
                fontWeight: 600,
                backgroundColor: palette.secondary.light,
                borderRadius: 0
            }} variant='contained' onClick={() => handleGoNext()}>Dalej</Button>
        </Grid>
    </Grid>
}

export default SecondStep;