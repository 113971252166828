import NaszBrowar from '../../assets/images/naszbrowar.png';
import {
    AppBar,
    Box,
    Drawer, Grid,
    IconButton, styled,
    Toolbar,
    Typography,
} from "@mui/material";
import {useState} from "react";
import MenuIcon from "../../ui/icons/MenuIcon";
import CloseIcon from "../../ui/icons/CloseIcon";
import Bosses from "../../assets/images/bosses.png";

const TypographyMenu = styled(Typography)(({theme}) => ({
    textTransform: 'capitalize',
    letterSpacing: '.2px',
    '&:hover': {
        cursor: 'pointer'
    }
}))

const MinHeader = (props: {marginRight: number, refers: any}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const {summaryRef, targetsRef, marketRef, historyRef, teamRef, productsRef, distributionRef, ownBreweryRef, financialResultsRef, documentsRef} = props.refers.current

    const handleScroll = (ref: any) => {
        window.scrollTo({
            top: ref.offsetTop + 500,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <Grid container component={"header"} pt={4}>
            <AppBar component={'div'} position="relative" sx={{zIndex: 100, boxShadow: 'none', backgroundColor: "white", maxWidth: "100%"}}>
            <Toolbar variant="dense" sx={{display: 'flex', justifyContent: {xs: 'space-between', md: 'space-around'}, padding: "14px 5%", minHeight: "0px", maxWidth: "100%"}}>
                <Box component="img" sx={{maxHeight: "100px", maxWidth: '200px'}} src={NaszBrowar} />
                <IconButton sx={{marginLeft: "27px"}} onClick={() => setOpenDrawer(true)}>
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
            <Drawer
                anchor={"right"}
                open={openDrawer}
                onClose={()=> setOpenDrawer(false)}
            >
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", width: "375px"}}>
                    <IconButton onClick={() => setOpenDrawer(false)} sx={{margin: "64px 0"}}>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '70%',
                        gap: '10px 40px'
                    }}>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(summaryRef.current); setOpenDrawer(false)}}>Podsumowanie</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(targetsRef.current); setOpenDrawer(false)}}>Cele Emisji</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(targetsRef.current); setOpenDrawer(false)}}>Jak Kupić Akcje</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(marketRef.current); setOpenDrawer(false)}}>Rynek</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(historyRef.current); setOpenDrawer(false)}}>Historia</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(teamRef.current); setOpenDrawer(false)}}>Zespół</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(productsRef.current); setOpenDrawer(false)}}>Produkty</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(distributionRef.current); setOpenDrawer(false)}}>Dystrybucja</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(ownBreweryRef.current); setOpenDrawer(false)}}>Własny Browar</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(financialResultsRef.current); setOpenDrawer(false)}}>Wyniki Finansowe i Prognozy</TypographyMenu>
                        <TypographyMenu variant="body2" onClick={() => {handleScroll(documentsRef.current); setOpenDrawer(false)}}>Dokumenty</TypographyMenu>
                    </Box>
                </Box>
            </Drawer>
            <Grid container item sx={{maxWidth: '1450px', margin: '120px auto 0 auto'}} alignItems={'center'} justifyContent={'flex-end'}>
                <Grid container item justifyContent={"flex-end"} sx={{maxWidth: '1066px'}} ml={'20px'} mr={'20px'}>
                    <Box component={'img'} src={Bosses} sx={{width: '100%'}} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MinHeader;
