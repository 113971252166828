import {Grid, Typography, Box, Divider} from "@mui/material";

const Products = (props: {marginRight: number, productsRef: any}) => {
    return (
        <Grid container ref={props.productsRef} flexDirection={'column'} component={"section"} sx={{maxWidth: {xs: '100%', lg: '930px'}, marginLeft: {xs: '20px', xl: 'auto'}}} pt={12} mr={{xs: '20px', xl: 0}}>
            <Typography variant="h1" pb={1} sx={{textTransform: 'uppercase'}}>Produkty</Typography>
            <Divider sx={{width: '100%', borderColor: '#707070'}} />
            <Box pt={2}>
                <Typography variant={'h4'} pt={2}>
                    Aktualny portfel produktów
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Portfel produktów dzieli się na dwie grupy: piwa dostępne w ciągłej sprzedaży oraz piwa sezonowe i nowości.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    W ciągłej sprzedaży mamy kilkanaście gatunków piwa. To zarówno europejska klasyka (np. pilsner, pszeniczne, stout), jak i piwa nowej fali (np. IPA z herbatą Earl Grey, APA, New England IPA).
                    Wśród piw sezonowych znajdują się takie piwa jak Black IPA, Milk Stout, czy Pszeniczne z mango.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Nowości z ostatniego roku to np. Żytnia IPA, Double White IPA, czy Sour Ale z agrestem.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Wszystkie piwa powstają w zgodzie z tradycją i dobrą praktyką piwowarską, z naturalnych surowców, nieśpiesznie (proces produkcji trwa od kilku tygodni do wielu miesięcy, np. RIS i porter leżakują
                    ponad rok).
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Wszystkie piwa cechuje zbalansowany smak. Są szeroko akceptowane, a konsumenci do nich wracają.
                </Typography>
            </Box>
            <Box pt={5}>
                <Typography variant={'h4'} pt={2}>
                    Innowacja produktowa
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Browar jest właścicielem znaków towarowych oraz receptur produkcyjnych.
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Browar jest także właścicielem innowacyjnej technologii produkcji piwa z wykorzystaniem ziaren wyjątkowych roślin (superfood) takich jak orkisz, amarantus czy komosa ryżowa. Technologia pozwala przenieść na piwo ponadprzeciętne walory odżywcze tych roślin (większą zawartość minerałów, witamin i aminokwasów).
                </Typography>
                <Typography variant={'body1'} pt={2}>
                    Innowacja jest odpowiedzią na zidentyfikowane trendy: autentyczności, naturalności i dbania o zdrowie, które są szczególnie silne w krajach cywilizacji zachodniej (Europie, Ameryka Pn., część Azji). Otwiera to szansę na rozwój eksportu. Pierwsze kontakty handlowe w tym zakresie zostały nawiązane już w roku 2018.
                </Typography>
            </Box>
        </Grid>
    )
}

export default Products;
