import {Grid, Box, Typography, styled, Collapse, useTheme} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useState} from "react";

const faqList = [
    {
        question: "Jaki jest cel emisji? Na co zostanie wydana kwota pozyskana w emisji akcji?",
        answer: "Środki pozyskane w ramach Oferty przeznaczone zostaną na:<br/>" +
            "<ul><li>Koszt nabycia i instalacji urządzeń do produkcji piwa, w kwocie 3.200 tys. zł</li>"+
        "<ul style='list-style-type: circle;'><li>linia do rozlewu w puszki: 1.100 tys. zł.</li>"+
        "<li>urządzenie do rozlewu i mycia beczek keg: 300 tys. zł.</li>"+
        "<li>urządzenia do oszczędzania energii: 500 tys. zł.</li>"+
        "<li>tanki do fermentacji i leżakowania piwa: 1.300 tys. zł.</li></ul>"+
        "<li>Koszt inwestycji rynkowych, w kwocie 1.100 tys. zł., w tym:</li>"+
        "<ul style='list-style-type: circle;'><li>zakup wyposażenia do sklepów i gastronomii: 800 tys.zł</li>"+
        "<li>rozwój nowej marki: 300 tys. zł.</li></ul>"+
        "<li>pokrycie kosztów emisji, w kwocie 300 tys. zł..</li></ul>"+
            "Razem: 4,6 mln. zł. (cztery miliony sześćset tysięcy złotych)"
    },
    {
        question: "Ile akcji jest oferowane do sprzedaży?",
        answer: "Oferujemy 1.600.000 akcji (jeden milion sześćset tysięcy akcji)."
    },
    {
        question: "Co się stanie, jeśli spółka nie pozyska oczekiwanej kwoty?",
        answer: "Spółka zakończyła budowę browaru i uruchomiła produkcję w lipcu 2022 roku. Emisja akcji służy\n" +
            "przyspieszeniu rozwoju. W przypadku pozyskania mniejszej kwoty inwestycje będą odbywały się w\n" +
            "wolniejszym tempie. Rozważane jest np. sfinansowanie linii rozlewniczej leasingiem."
    },
    {
        question: "Czy spółka planuje wypłatę dywidendy?",
        answer: "Tak, planujemy wypłacać dywidendę. Terminy wypłaty dywidendy oraz jej wysokość będą ściśle " +
        "związane z wynikami finansowymi Spółki. Priorytetem w kolejnych latach jest inwestycja we wzrost " +
        "wydajności browaru, co może wpłynąć na decyzje w tej sprawie. Warunkiem wypłaty dywidendy jest " +
        "podjęcie uchwały przez Walne Zgromadzenie Akcjonariuszy."
    },
    {
        question: "Czy akcje zakupione w emisji Browar Za Miastem sp. z o.o. SKA będę imienne, czy na okaziciela?",
        answer: "Akcje będą imienne, podobnie jak akcje poprzednich serii."
    },
    {
        question: "Jakie są najważniejsze prawa posiadacza akcji Browar Za Miastem sp. Z o.o. SKA zakupionych\n" +
            "w emisji?",
        answer: "Najważniejsze prawa akcjonariusza to:<br/>\n" +
            "– udział w Walnym Zgromadzeniu Spółki oraz prawo głosu na Walnym Zgromadzeniu,<br/>\n" +
            "– prawo do złożenia żądania udzielenia informacji dotyczących Spółki,<br/>\n" +
            "– prawo udziału w zysku Spółki, o podziale którego decyduje Walne Zgromadzenie (dywidenda),<br/>\n" +
            "– prawo swobodnego dysponowania posiadanymi akcjami Spółki (np. sprzedaż),<br/>\n" +
            "– prawo do udziału w podziale majątku Spółki w przypadku jej likwidacji,<br/>\n" +
            "– żądanie wydania dokumentu akcji."
    },
    {
        question: "Jak będzie można odebrać upominki związane z zakupem akcji?",
        answer: "Każdy z inwestorów otrzyma otwieracz firmowy, który zostanie przesłany pocztą lub kurierem. Zniżki " +
        "na piwo będzie można zrealizować na festiwalach piwnych oraz w sklepie przy Browarze."
    },
    {
        question: "W jaki sposób spółka będzie komunikować się z inwestorami? Jak będzie informować o swoich\n" +
            "wynikach i planach?",
        answer: "Spółką regularnie komunikuje się ze swoimi akcjonariuszami. Komunikacja odbywa się za " +
        "pośrednictwem strony internetowej, zamkniętej grupy na profilu społecznościowym oraz email. " +
        "Spółka regularnie informuje o wynikach oraz swojej działalności (rozbudowa browaru, nowe " +
        "produkty, dystrybucja produktów, itd.) . Poza tym realizuje działania wymagane prawem, takie jak " +
        "Walne Zgromadzenie Akcjonariuszy."
    },
    {
        question: "Jak obecna sytuacja na świecie wpływa na sytuację spółki?",
        answer: "Spółka monitoruje sytuację oraz aktywnie na nią reaguje. W okresie problemów związanych z\n" +
            "pandemią Covid-19, w tym ograniczenia możliwości sprzedaży (zamknięcie gastronomii i hoteli,\n" +
            "wstrzymanie eventów) Spółka zwiększyła intensywność działań w innych kanałach sprzedaży.\n" +
            "W czasie dynamicznego wzrostu kosztów, w tym kosztów energii, paliw, opakowań, Spółka\n" +
            "zwiększyła jednorazowe zamówienia, co obniżyło skalę podwyżki kosztów. Rozpoczęcie produkcji we\n" +
            "własnym browarze pozwoli na obniżenie kosztów działalności."
    },
    {
        question: "Od kiedy istnieje spółka Browar za Miastem i jakie jest doświadczenie rynkowe jej właścicieli?",
        answer: "Spółka powstała w 2016 r. Obaj założyciele mają doświadczenie zawodowe w branży od lat 90-tych.\n" +
            "Jeden z nas ponad 25 lat, drugi kilkanaście lat. Nasze doświadczenie obejmuje strategię, zarządzanie\n" +
            "markami, relacje z klientami. Kierowaliśmy projektami, których budżet wynosił kilkadziesiąt milionów\n" +
            "złotych. Nasz Piwowar warzy piwo od ponad 40 lat! Pracował nad technologią kilku browarów."
    },
    {
        question: "Jakie są wyróżniki Browaru Za Miastem na tle innych browarów rzemieślniczych?",
        answer: "Łączymy zwinność działania małej firmy z uporządkowaniem dużej firmy. Obserwujemy rynek i\n" +
            "szybko reagujemy na zmiany. Mamy wieloletnie doświadczenie w biznesie i produkcji piwa. Nasze\n" +
            "piwa są szeroko akceptowane oraz mają szeroką dystrybucję. Zbudowaliśmy mocne relacje\n" +
            "z partnerami biznesowymi. Mamy własny zakład produkcyjny."
    },
    {
        question: "Ile produktów znajduje się aktualnie w portfelu marki?",
        answer: "Aktualnie mamy ponad 20 różnych piw. Większość z nich jest w ciągłej dostępności. W każdym roku\n" +
            "wprowadzamy nowości. Mamy w ofercie także piwa sezonowe, lżejsze na lato i bardziej ekstraktywne\n" +
            "na zimę."
    },
    {
        question: "Czy budowa browaru już się zakończyła?",
        answer: "Tak. Budowa browaru zakończyła się. Wystarczyły 2 lata od pierwszych prac na budowie do\n" +
            "uruchomienia produkcji. Spółka rozpoczęła działalność produkcyjną w lipcu 2022 roku. Spółka\n" +
            "kontynuuje współpracę w zakresie produkcji z podmiotami zewnętrznymi. Obecnie trwa okres\n" +
            "przejściowy między uruchomieniem produkcji i uzyskaniem pełnych mocy produkcyjnych.\n" +
            "Współpraca z innymi podmiotami będzie stopniowo wygaszana. Planuje się, że do końca roku 2022\n" +
            "znakomita większość produkcji będzie produkowana we własnym zakładzie (browarze)."
    },
    {
        question: "Na czym polega innowacyjna technologia, która używana będzie w nowo otwartym browarze i kto\n" +
            "ją zaopiniował?",
        answer: "Innowacyjna technologia dotyczy wykorzystania w produkcji piwa surowców o wyjątkowych\n" +
            "właściwościach odżywczych, takich jak np. orkisz, owies czy amarantus. Opracowana przez nas\n" +
            "technologia umożliwia przeniesienie tych właściwości z surowca na piwo, bez ich utraty.\n" +
            "Opracowaliśmy ją w Spółce, wspólnie z naukowcami z Politechniki Łódzkiej. Technologia został\n" +
            "pozytywnie zaopiniowana przez naukowców z Uniwersytetu Przyrodniczego we Wrocławiu."
    },
    {
        question: "Czy opracowana przez spółkę innowacyjna technologia warzenia piw jest chroniona?",
        answer: "Technologia jest chroniona patentem w Polsce i za granicą. Na rzecz Spółki został udzielony PATENT\n" +
            "NR 240859 na wynalazek pt. „Sposób otrzymywania piwa i kocioł do wytwarzania zacieru”. Patent\n" +
            "trwa od dnia 2017-03-23. Patent obowiązuje do 2037-03-23."
    },
]

const Faq = () => {
    const [faq, setFaq] = useState(faqList.map(f => false))
    const {palette} = useTheme();

    return (
        <Grid container component={"section"} sx={{width: '100%'}} justifyContent={'center'}>
            <Box pt={8}>
                <Typography variant={'h4'} sx={{textDecoration: 'underline'}}>
                    Najczęściej zadawane pytania
                </Typography>
                {faqList.map((f, index) => {
                  return (
                    <Grid item container mt={4} sx={{border: "1px solid " + palette.primary.main,}} justifyContent={"space-between"} wrap={"nowrap"}>
                          <Box sx={{width: "80%", padding: "25px 10px 25px 66px"}}>
                              <Typography variant={"body2"} sx={{display: "inline-block", fontWeight: 600, borderBottom: faq[index] ? "1px solid " + palette.primary.main : "none", paddingBottom: faq[index] ? "10px" : "0"}}>
                                  {f.question}
                              </Typography>
                              <Collapse in={faq[index]}>
                                  <Typography variant={"body2"} sx={{fontWeight: 400, marginTop: "30px", marginBottom: "15px"}} dangerouslySetInnerHTML={{__html: f.answer}}/>
                              </Collapse>

                          </Box>
                        <Box
                            onClick={() => {
                                const newFaq = [...faq];
                                newFaq[index] = !faq[index];
                                setFaq(newFaq);
                            }}
                            sx={{
                            width: "70px",
                            minHeight: "100%",
                            backgroundColor: palette.primary.main,
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            paddingTop: "23px"
                        }}
                        >
                            {faq[index] ? <ArrowBackIosIcon sx={{marginLeft: "25px"}}/> : <ArrowForwardIosIcon sx={{marginLeft: "25px"}}/>}
                        </Box>
                  </Grid>
                  )
                })}
                {/*<Grid item container mt={4}>*/}
                {/*    <Box>*/}
                {/*        <TypographyStyled>*/}
                {/*            Jaki jest cel emisji? Na co zostanie wydana kwota pozyskana w emisji akcji?*/}
                {/*        </TypographyStyled>*/}
                {/*        <Collapse in={true}>*/}
                {/*            <Typography variant={"body2"} sx={{fontWeight: 400}}>*/}
                {/*                Pozyskane środki zostaną przeznaczone na rozwój spółki. Dotyczyć to będzie głównie rozbudowy*/}
                {/*                mocy produkcyjnych oraz inwestycji rynkowych. Szczegóły podane są na stronie naszbrowar.pl*/}
                {/*            </Typography>*/}
                {/*        </Collapse>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
                {/*<BoxLiStyled mt={4}>*/}
                {/*    <TypographyStyled>*/}
                {/*        Jakie są plany spółki w przypadku niezebrania pełnej kwoty podczas emisji?*/}
                {/*    </TypographyStyled>*/}
                {/*    <Box component={'img'} src={arrowFaq} sx={{'&:hover': {cursor: 'pointer'}, transform: `${secondFaq ? 'rotate(0.25turn)' : 'rotate(0turn)'}`}} onClick={() => setSecondFaq(!secondFaq)} />*/}
                {/*</BoxLiStyled>*/}
                {/*{secondFaq && <Typography variant={'body1'} pt={2}>*/}
                {/*    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum*/}
                {/*    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum.*/}
                {/*</Typography>}*/}
                {/*<BoxLiStyled mt={4}>*/}
                {/*    <TypographyStyled>*/}
                {/*        Jakie są plany spółki w przypadku niezebrania pełnej kwoty podczas emisji?*/}
                {/*    </TypographyStyled>*/}
                {/*    <Box component={'img'} src={arrowFaq} sx={{'&:hover': {cursor: 'pointer'}, transform: `${thirdFaq ? 'rotate(0.25turn)' : 'rotate(0turn)'}`}} onClick={() => setThirdFaq(!thirdFaq)} />*/}
                {/*</BoxLiStyled>*/}
                {/*{thirdFaq && <Typography variant={'body1'} pt={2}>*/}
                {/*    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum*/}
                {/*    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum.*/}
                {/*</Typography>}*/}
                {/*<BoxLiStyled mt={4}>*/}
                {/*    <TypographyStyled>*/}
                {/*        Jakie są plany spółki w przypadku niezebrania pełnej kwoty podczas emisji?*/}
                {/*    </TypographyStyled>*/}
                {/*    <Box component={'img'} src={arrowFaq} sx={{'&:hover': {cursor: 'pointer'}, transform: `${fourthFaq ? 'rotate(0.25turn)' : 'rotate(0turn)'}`}} onClick={() => setFourthFaq(!fourthFaq)} />*/}
                {/*</BoxLiStyled>*/}
                {/*{fourthFaq && <Typography variant={'body1'} pt={2}>*/}
                {/*    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum*/}
                {/*    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum.*/}
                {/*</Typography>}*/}
            </Box>
        </Grid>
    )
}

export default Faq;
