import {useContext, useEffect} from "react";
import {Alert,  Box, Grid, Snackbar} from "@mui/material";
import SnackbarContext from "../../store/snackbar-context";
import LogoHeader from "./LogoHeader";
import FirstStep from "./FirstStep";
import BuySharesContext from "../../store/buy-shares-context";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixStep from "./SixStep";
import Footer from "../../components/Footer";

const BuyShares = () => {
    const {msg, severity, setMsg} = useContext(SnackbarContext);
    const {step} = useContext(BuySharesContext);


    useEffect(() => {
        switch (step) {
            case 1: {
                const secondStep = document.getElementById("second-step");
                if(secondStep) {
                    secondStep.scrollIntoView({behavior: "smooth"})
                }
                break;
            }
            case 2: {
                const thirdStep = document.getElementById("third-step");
                if(thirdStep) {
                    thirdStep.scrollIntoView({behavior: "smooth"})
                }
                break;
            }
            case 3: {
                const fourthStep = document.getElementById("fourth-step");
                if(fourthStep) {
                    fourthStep.scrollIntoView({behavior: "smooth"})
                }
                break;
            }
            case 4: {
                const fifthStep = document.getElementById("fifth-step");
                if(fifthStep) {
                    fifthStep.scrollIntoView({behavior: "smooth"})
                }
                break;
            }

        }
    }, [step])

    return (
        <Grid container justifyContent={'center'} alignItems={'center'}>
            <LogoHeader/>
            <FirstStep/>
            {step >= 1 && <SecondStep/>}
            {step >= 2 && <ThirdStep/>}
            {step >= 3 && <FourthStep/>}
            {step >= 4 && <FifthStep/>}
            {step >= 4 && <SixStep/>}
            <Box mt={"40px"}>&nbsp;</Box>
            <Footer />
            <Snackbar anchorOrigin={{vertical: "bottom", horizontal: "right"}} open={msg != ""}
                      onClose={() => setMsg({msg: ""})}>
                <Alert severity={severity}>{msg}</Alert>
            </Snackbar>
        </Grid>)
}

export default BuyShares;