import {Box, Grid, Link, Typography, useTheme} from "@mui/material";

const Footer = () => {
    const {palette} = useTheme();

    return (
        <Grid container component={"footer"} sx={{width: '100%', justifyContent: 'center', backgroundColor: palette.primary.light}} pt={4} pb={4} mt={4}>
            <Grid container item ml={{xs: '20px', xl: '0px'}} mr={{xs: '20px', xl: '0px'}} sx={{maxWidth: '1350px', gap: '20px', justifyContent: 'space-between', flexDirection: {xs: 'column', lg: 'row', alignItems: 'center'}}}>
                <Box sx={{display: 'flex', flexDirection: 'column', width: {xs: '340px', lg: 'auto'}}}>
                    <Typography sx={{fontSize: '18px!important', fontWeight: 600}}>
                        BROWAR ZA MIASTEM Sp. z o.o. SKA
                    </Typography>
                    <Typography sx={{fontSize: '18px!important', fontWeight: 500}}>
                        ul. Południowa 14
                    </Typography>
                    <Typography sx={{fontSize: '18px!important', fontWeight: 500}}>
                        62-080 Rumianek
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', width: {xs: '340px', lg: 'auto'}}}>
                    <Typography sx={{fontSize: '18px!important', fontWeight: 600}}>
                        Masz pytania? Napisz do nas:
                    </Typography>
                    <Typography sx={{fontSize: '18px!important', fontWeight: 400}}>
                        inwestorzy@naszbrowar.pl
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', width: {xs: '340px', lg: 'auto'}}}>
                    <Link href={"/regulamin.pdf"} target="_blank" download sx={{color: '#000', fontSize: '18px', fontWeight: 600, textDecoration: 'none'}}>
                        Regulamin
                    </Link>
                    <Link href={"/polityka-prywatności.pdf"} download target="_blank" sx={{color: '#000', fontSize: '18px', fontWeight: 400, textDecoration: 'none'}}>
                        Informacje o przetwarzaniu danych osobowych
                    </Link>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Footer;
